import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgStarFilledIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 24' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M13.86.844A1.51 1.51 0 0 0 12.497 0c-.583 0-1.108.328-1.364.844l-3.044 6.2-6.799.994A1.51 1.51 0 0 0 .074 9.055a1.5 1.5 0 0 0 .374 1.533L5.38 15.42l-1.165 6.83a1.5 1.5 0 0 0 .611 1.466 1.54 1.54 0 0 0 1.6.108l6.074-3.21 6.074 3.21c.512.267 1.132.23 1.6-.108.47-.337.706-.904.611-1.467l-1.169-6.829 4.933-4.832c.407-.399.554-.994.374-1.533a1.51 1.51 0 0 0-1.216-1.017l-6.804-.993z'
			/>
		</svg>
	);
});
export default memo(SvgStarFilledIcon);
