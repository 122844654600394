import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgSpotMapGreenIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 42 61' ref={ref} {...props}>
			<g fill='none' fillRule='evenodd' transform='translate(3 2)'>
				<path
					fill='#6EAC52'
					stroke='#EDEEEF'
					strokeWidth={4}
					d='M18.322 55.468C23 55.468 36.643 27.941 36.643 18S28.44 0 18.322 0C8.202 0 0 8.059 0 18s13.643 37.468 18.322 37.468Z'
				/>
				<circle cx={18} cy={16} r={6} fill='#EDEEEF' />
			</g>
		</svg>
	);
});
export default memo(SvgSpotMapGreenIcon);
