import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgArrowDownIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 21 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M9.265 24.477a1.727 1.727 0 0 0 2.476 0l8.747-8.93a1.815 1.815 0 0 0 0-2.527 1.727 1.727 0 0 0-2.477 0l-5.762 5.887V1.786C12.25.798 11.468 0 10.5 0S8.75.798 8.75 1.786v17.116L2.99 13.025a1.727 1.727 0 0 0-2.477 0 1.815 1.815 0 0 0 0 2.528l8.747 8.93z'
			/>
		</svg>
	);
});
export default memo(SvgArrowDownIcon);
