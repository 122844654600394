import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgCopyIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M18.857 16.406H9.43a.786.786 0 0 1-.786-.781v-12.5c0-.43.353-.781.786-.781h6.88l3.334 3.315v9.966c0 .43-.354.781-.786.781M9.43 18.75h9.428c1.734 0 3.143-1.401 3.143-3.125V5.659c0-.62-.25-1.216-.692-1.655L17.978.688A2.36 2.36 0 0 0 16.313 0H9.43C7.695 0 6.286 1.401 6.286 3.125v12.5c0 1.724 1.41 3.125 3.143 3.125M3.143 6.25C1.409 6.25 0 7.651 0 9.375v12.5C0 23.599 1.41 25 3.143 25h9.428c1.734 0 3.143-1.401 3.143-3.125v-1.562h-2.357v1.562c0 .43-.353.781-.786.781H3.143a.786.786 0 0 1-.786-.781v-12.5c0-.43.354-.781.786-.781h1.571V6.25z'
			/>
		</svg>
	);
});
export default memo(SvgCopyIcon);
