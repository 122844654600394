import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgLockCheckIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M18.08 13.84a5.58 5.58 0 1 1 0 11.16 5.58 5.58 0 0 1 0-11.16M10.268 0c3.24 0 5.867 2.648 5.867 5.915v2.958h1.467c1.618 0 2.934 1.326 2.934 2.957v1.598a6.4 6.4 0 0 0-2.2-.477v-1.12c0-.407-.33-.74-.734-.74H2.934a.74.74 0 0 0-.734.74v8.872c0 .407.33.74.734.74h8.996a6.5 6.5 0 0 0 1.26 2.218H2.934C1.316 23.66 0 22.334 0 20.703V11.83c0-1.63 1.316-2.957 2.934-2.957H4.4V5.915C4.4 2.648 7.027 0 10.268 0m10.107 17.291-3.445 3.616-1.556-1.632a.457.457 0 0 0-.669 0 .514.514 0 0 0 0 .702l1.89 1.984a.457.457 0 0 0 .668 0l3.78-3.967a.514.514 0 0 0 0-.703.456.456 0 0 0-.668 0M10.268 2.218C8.242 2.218 6.6 3.873 6.6 5.915v2.958h7.334V5.915c0-2.042-1.641-3.697-3.667-3.697'
			/>
		</svg>
	);
});
export default memo(SvgLockCheckIcon);
