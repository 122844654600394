import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgUserFilledIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M11 12.5c3.472 0 6.286-2.798 6.286-6.25S14.472 0 11 0 4.714 2.798 4.714 6.25 7.528 12.5 11 12.5m-2.244 2.344C3.919 14.844 0 18.74 0 23.55 0 24.35.653 25 1.458 25h19.084c.805 0 1.458-.65 1.458-1.45 0-4.81-3.919-8.706-8.756-8.706z'
			/>
		</svg>
	);
});
export default memo(SvgUserFilledIcon);
