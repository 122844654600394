import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgSidebarSalesOffersIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 22' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M9.96 0c.739 0 1.446.288 1.967.804l3.928 3.888c.52.516.812 1.216.812 1.947v1.895a7.7 7.7 0 0 0-2.084.936V6.875h-3.472A1.38 1.38 0 0 1 9.722 5.5V2.063H2.778a.693.693 0 0 0-.695.687v16.5c0 .378.313.688.695.688h9.57c.52.79 1.19 1.482 1.966 2.032q-.208.03-.42.03H2.777C1.246 22 0 20.767 0 19.25V2.75C0 1.233 1.246 0 2.778 0zm8.79 9.625c3.452 0 6.25 2.77 6.25 6.188S22.202 22 18.75 22s-6.25-2.77-6.25-6.187 2.798-6.188 6.25-6.188m2.53 3.088-5.66 5.605a.416.416 0 0 0 0 .594.427.427 0 0 0 .6 0l5.66-5.605a.416.416 0 0 0 0-.594.427.427 0 0 0-.6 0m-.266 4.501a.845.845 0 0 0-.85.841c0 .465.381.842.85.842a.845.845 0 0 0 .849-.842.845.845 0 0 0-.85-.84m-4.952-4.373a.84.84 0 0 0-.425.729c0 .3.162.578.425.728.262.15.586.15.849 0a.84.84 0 0 0 .424-.728.84.84 0 0 0-.424-.729.86.86 0 0 0-.85 0'
			/>
		</svg>
	);
});
export default memo(SvgSidebarSalesOffersIcon);
