import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgBellFilledIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M10.999 0c-.87 0-1.571.698-1.571 1.563V2.5c-3.585.723-6.286 3.877-6.286 7.656v.918c0 2.295-.85 4.512-2.382 6.23l-.363.406a1.561 1.561 0 0 0 1.173 2.602h18.857c.62 0 1.179-.36 1.434-.922a1.55 1.55 0 0 0-.26-1.68l-.363-.405a9.36 9.36 0 0 1-2.382-6.23v-.919c0-3.779-2.7-6.933-6.286-7.656v-.937C12.57.698 11.868 0 11 0m2.224 24.087c.59-.586.919-1.382.919-2.212H7.856c0 .83.33 1.626.918 2.212.59.586 1.39.913 2.225.913s1.635-.327 2.224-.913'
			/>
		</svg>
	);
});
export default memo(SvgBellFilledIcon);
