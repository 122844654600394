import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgSidebarSpotIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19 25' ref={ref} {...props}>
			<g fill='none' fillRule='evenodd' stroke='currentColor' strokeWidth={1.912}>
				<path
					strokeLinecap='round'
					strokeLinejoin='round'
					d='M12.829 9.302c0 1.848-1.489 3.347-3.325 3.347S6.179 11.15 6.179 9.302s1.489-3.347 3.325-3.347 3.325 1.499 3.325 3.347'
				/>
				<path d='M9.504.956c2.354 0 4.48 1.008 6.022 2.63 1.558 1.642 2.518 3.91 2.518 6.414 0 3.4-1.025 6.157-2.384 8.303-2.347 3.705-5.74 5.74-6.16 5.74s-3.813-2.035-6.16-5.74C1.981 16.157.956 13.399.956 10c0-2.503.961-4.77 2.521-6.412C5.02 1.965 7.15.956 9.504.956Z' />
			</g>
		</svg>
	);
});
export default memo(SvgSidebarSpotIcon);
