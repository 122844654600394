import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgEllipsisIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 6' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M0 3c0-1.657 1.45-3 3.24-3s3.241 1.343 3.241 3-1.45 3-3.24 3S0 4.657 0 3m9.26 0c0-1.657 1.45-3 3.24-3s3.24 1.343 3.24 3-1.45 3-3.24 3-3.24-1.343-3.24-3m12.5-3C23.55 0 25 1.343 25 3s-1.45 3-3.24 3-3.241-1.343-3.241-3 1.45-3 3.24-3'
			/>
		</svg>
	);
});
export default memo(SvgEllipsisIcon);
