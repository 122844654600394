import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgMastercardIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 16' ref={ref} {...props}>
			<g fill='none' fillRule='nonzero'>
				<path fill='#FF5A00' d='M15.869 1.652H9.11v12.142h6.758z' />
				<path fill='#EB001B' d='M9.56 7.723a7.75 7.75 0 0 1 2.94-6.07A7.718 7.718 0 0 0 .013 7.723a7.718 7.718 0 0 0 12.487 6.07 7.7 7.7 0 0 1-2.94-6.071' />
				<path
					fill='#F79E1B'
					d='M24.987 7.723A7.718 7.718 0 0 1 12.5 13.794a7.68 7.68 0 0 0 2.94-6.071 7.75 7.75 0 0 0-2.94-6.07A7.67 7.67 0 0 1 17.261 0c4.272 0 7.726 3.477 7.726 7.723'
				/>
			</g>
		</svg>
	);
});
export default memo(SvgMastercardIcon);
