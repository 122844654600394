import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgSidebarReportsIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 26' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M2.969 22.375V3.625c0-.43.351-.781.781-.781h7.813V6.75a1.56 1.56 0 0 0 1.562 1.563h3.906v14.062c0 .43-.351.781-.781.781H3.75a.784.784 0 0 1-.781-.781M3.75.5A3.13 3.13 0 0 0 .625 3.625v18.75A3.13 3.13 0 0 0 3.75 25.5h12.5a3.13 3.13 0 0 0 3.125-3.125V8.044c0-.83-.327-1.626-.913-2.212l-4.424-4.419A3.12 3.12 0 0 0 11.831.5zm1.563 4.688a.784.784 0 0 0-.782.78c0 .43.352.782.782.782h3.906c.43 0 .781-.352.781-.781a.784.784 0 0 0-.781-.782zm0 3.125a.784.784 0 0 0-.782.78c0 .43.352.782.782.782h3.906c.43 0 .781-.352.781-.781a.784.784 0 0 0-.781-.781zm2.646 10.83.479-1.602.815 2.715a.77.77 0 0 0 .679.552.785.785 0 0 0 .766-.43l.518-1.04a.17.17 0 0 1 .151-.093c.064 0 .122.034.152.093l.517 1.04c.132.264.405.43.698.43h1.954c.43 0 .78-.352.78-.782a.784.784 0 0 0-.78-.78h-1.47l-.303-.606a1.73 1.73 0 0 0-1.548-.957c-.42 0-.82.151-1.128.42l-.552-1.832a1.304 1.304 0 0 0-2.5.005l-.727 2.417a.78.78 0 0 1-.747.557h-.4a.784.784 0 0 0-.782.781c0 .43.352.782.782.782h.4a2.345 2.345 0 0 0 2.246-1.67'
			/>
		</svg>
	);
});
export default memo(SvgSidebarReportsIcon);
