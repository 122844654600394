import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgListIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 21' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M1.21 0C.54 0 0 .54 0 1.212v2.423c0 .671.54 1.211 1.21 1.211h2.419c.67 0 1.21-.54 1.21-1.211V1.212C4.839.54 4.299 0 3.629 0zM8.87.808c-.892 0-1.613.722-1.613 1.615 0 .894.72 1.615 1.613 1.615h14.516c.892 0 1.613-.721 1.613-1.615 0-.893-.72-1.615-1.613-1.615zm0 8.077c-.892 0-1.613.721-1.613 1.615s.72 1.615 1.613 1.615h14.516c.892 0 1.613-.721 1.613-1.615s-.72-1.615-1.613-1.615zm0 8.077c-.892 0-1.613.721-1.613 1.615 0 .893.72 1.615 1.613 1.615h14.516c.892 0 1.613-.722 1.613-1.615 0-.894-.72-1.615-1.613-1.615zM0 9.288v2.424c0 .67.54 1.211 1.21 1.211h2.419c.67 0 1.21-.54 1.21-1.211V9.288c0-.67-.54-1.211-1.21-1.211h-2.42C.54 8.077 0 8.617 0 9.288m1.21 6.866c-.67 0-1.21.54-1.21 1.211v2.423C0 20.46.54 21 1.21 21h2.419c.67 0 1.21-.54 1.21-1.212v-2.423c0-.671-.54-1.211-1.21-1.211z'
			/>
		</svg>
	);
});
export default memo(SvgListIcon);
