import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgRefreshIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 24' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M5.092 9.22A8.25 8.25 0 0 1 7.06 6.105a8.335 8.335 0 0 1 11.789 0l.89.896h-1.786c-.922 0-1.667.745-1.667 1.667s.745 1.667 1.667 1.667h5.829c.922 0 1.667-.745 1.667-1.667V2.833c0-.922-.745-1.667-1.667-1.667s-1.667.745-1.667 1.667v1.834l-.912-.917C16.646-.808 9.26-.808 4.701 3.75a11.6 11.6 0 0 0-2.75 4.366 1.667 1.667 0 0 0 3.14 1.11zm-3.444 4.517a1.66 1.66 0 0 0-.714.427 1.62 1.62 0 0 0-.463.927q-.022.133-.021.266v5.814c0 .922.745 1.667 1.667 1.667s1.667-.745 1.667-1.667v-1.829l.917.912c4.558 4.553 11.945 4.553 16.498 0a11.6 11.6 0 0 0 2.756-4.36 1.667 1.667 0 0 0-3.141-1.11 8.25 8.25 0 0 1-1.97 3.115 8.335 8.335 0 0 1-11.788 0l-.006-.005-.89-.891h1.792c.922 0 1.667-.745 1.667-1.667s-.745-1.667-1.667-1.667H2.138q-.125 0-.25.016-.125.014-.24.052'
			/>
		</svg>
	);
});
export default memo(SvgRefreshIcon);
