import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgBarChartFilledIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M4.071.5A3.575 3.575 0 0 0 .5 4.071V21.93A3.575 3.575 0 0 0 4.071 25.5H21.93a3.575 3.575 0 0 0 3.571-3.571V4.07A3.575 3.575 0 0 0 21.929.5zm3.572 10.714c.988 0 1.786.798 1.786 1.786v5.357c0 .988-.798 1.786-1.786 1.786a1.784 1.784 0 0 1-1.786-1.786V13c0-.988.798-1.786 1.786-1.786m3.571-3.571c0-.988.798-1.786 1.786-1.786s1.786.798 1.786 1.786v10.714c0 .988-.798 1.786-1.786 1.786a1.784 1.784 0 0 1-1.786-1.786zm7.143 7.143c.988 0 1.786.798 1.786 1.785v1.786c0 .988-.798 1.786-1.786 1.786a1.784 1.784 0 0 1-1.786-1.786v-1.786c0-.987.798-1.785 1.786-1.785'
			/>
		</svg>
	);
});
export default memo(SvgBarChartFilledIcon);
