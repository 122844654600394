'use client';

import * as React from 'react';
import { CheckIcon, AngleUpDownIcon, XFilledIcon } from '@aiq/icons/components';

import { cn } from '../utils';
import Button from './button';
import { Command, CommandEmpty, CommandInput, CommandItem, CommandList } from './command';
import { Popover, PopoverContent, PopoverTrigger } from './popover';
import { FormTitle, inputClassNamesBase } from './forms';

export interface ComboboxProps {
	value: string;
	onChange: (value: string) => void;
	items: { value: string; label: string }[];
	noValueLabel: string;
	noResultsLabel: string;
	searchPlaceholder: string;
	title?: string;
	className?: string;
}

export function Combobox({ value, onChange, items, noValueLabel, searchPlaceholder, noResultsLabel, title, className }: ComboboxProps) {
	const [open, setOpen] = React.useState(false);

	return (
		<Popover modal={false} open={open} onOpenChange={setOpen}>
			<div>
				{title ? <FormTitle title={title} /> : null}
				<PopoverTrigger asChild>
					<Button role='combobox' aria-expanded={open} className={cn('w-full min-w-[250px] justify-between form-input shadow-none', inputClassNamesBase, className)}>
						{value ? items.find(item => item.value === value)?.label : noValueLabel}
						<span className='inline-flex ml-auto'>
							{value ? (
								<button onClick={() => onChange('')} className='mr-2 appearance-none'>
									<XFilledIcon className={cn('h-3 w-3 shrink-0 text-neutral-500')} />
								</button>
							) : null}
							<AngleUpDownIcon className='ml-2 h-3 w-3 shrink-0 opacity-50' />
						</span>
					</Button>
				</PopoverTrigger>
			</div>
			<PopoverContent style={{ width: 'var(--radix-popover-trigger-width)' }} className='p-0 bg-white'>
				<Command>
					<CommandInput className='rounded-lg outline-none focus:outline-none focus:border-none focus:ring-0 border-none ring-0' placeholder={searchPlaceholder} />
					<CommandEmpty>{noResultsLabel}</CommandEmpty>
					<CommandList>
						{items.map(item => (
							<CommandItem
								key={item.value}
								value={item.value}
								className='py-2 '
								onSelect={currentValue => {
									onChange(currentValue === value ? '' : currentValue);
									setOpen(false);
								}}
							>
								{/* <CheckIcon className={cn('mr-2 h-4 w-4', value === item.value ? 'opacity-100' : 'opacity-0')} /> */}
								{item.label}
							</CommandItem>
						))}
					</CommandList>
				</Command>
			</PopoverContent>
		</Popover>
	);
}
