import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgInfoCircleFilledIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M12.5 25C19.404 25 25 19.404 25 12.5S19.404 0 12.5 0 0 5.596 0 12.5 5.596 25 12.5 25m-1.953-8.594h1.172v-3.125h-1.172a1.17 1.17 0 0 1-1.172-1.172c0-.649.522-1.171 1.172-1.171h2.344c.649 0 1.172.522 1.172 1.171v4.297h.39c.65 0 1.172.523 1.172 1.172a1.17 1.17 0 0 1-1.172 1.172h-3.906a1.17 1.17 0 0 1-1.172-1.172c0-.65.522-1.172 1.172-1.172M12.5 6.25a1.563 1.563 0 1 1 0 3.125 1.563 1.563 0 0 1 0-3.125'
			/>
		</svg>
	);
});
export default memo(SvgInfoCircleFilledIcon);
