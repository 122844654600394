import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgTripIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 23' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M8.594 2.683V4.6h7.812V2.683a.39.39 0 0 0-.39-.383H8.984a.39.39 0 0 0-.39.383M6.25 4.6V2.683C6.25 1.203 7.476 0 8.984 0h7.032c1.508 0 2.734 1.203 2.734 2.683V23H6.25V4.6m-3.125 0h1.563V23H3.125C1.401 23 0 21.625 0 19.933V7.667C0 5.975 1.401 4.6 3.125 4.6M21.875 23h-1.562V4.6h1.562C23.599 4.6 25 5.975 25 7.667v12.266C25 21.625 23.599 23 21.875 23'
			/>
		</svg>
	);
});
export default memo(SvgTripIcon);
