import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgMoonIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 21 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M12.218 0C5.467 0 0 5.597 0 12.5S5.467 25 12.218 25c3.313 0 6.314-1.35 8.517-3.538a.91.91 0 0 0 .17-1.043.875.875 0 0 0-.93-.475 9.4 9.4 0 0 1-1.645.145c-5.297 0-9.594-4.397-9.594-9.821 0-3.672 1.968-6.87 4.882-8.555a.9.9 0 0 0 .42-.965.88.88 0 0 0-.781-.698 13 13 0 0 0-1.039-.044z'
			/>
		</svg>
	);
});
export default memo(SvgMoonIcon);
