import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgNoGuidesIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 25' ref={ref} {...props}>
			<g fill='none' fillRule='evenodd'>
				<path
					fill='currentColor'
					fillRule='nonzero'
					d='M11.438 12.628c.588 0 1.158.077 1.701.222a8 8 0 0 0-1.12 1.69 5 5 0 0 0-.582-.037H7.064a4.687 4.687 0 0 0-4.676 4.375h8.825c.059.65.194 1.278.397 1.875H1.75c-.691 0-1.25-.559-1.25-1.25v-.313a6.56 6.56 0 0 1 6.563-6.562zM4.968.753c.141 0 .282.047.391.137l.61.488.117.094.031.023.008.008c.398.293.93.324 1.363.078l1.18-.672a1.17 1.17 0 0 1 1.164 0l1.18.672c.43.246.965.215 1.363-.078q.023-.017.035-.028l.117-.093.614-.492c.109-.09.25-.137.39-.137h.094c.344 0 .625.281.625.625v5a5 5 0 1 1-10 0v-5c0-.344.281-.625.625-.625zm12.407 1.25a4.373 4.373 0 0 1 4.375 4.375 4.37 4.37 0 0 1-1.98 3.663 8.09 8.09 0 0 0-3.504.568 4.4 4.4 0 0 1-1.727-.9q.031-.044.055-.089a6.2 6.2 0 0 0 .906-3.242V2.425a4.36 4.36 0 0 1 1.875-.422m-5 3.75h-6.25v.625a3.124 3.124 0 1 0 6.25 0z'
				/>
				<g transform='translate(12.881 11.629)'>
					<circle cx={6.309} cy={6.309} r={5.408} stroke='currentColor' strokeWidth={1.803} />
					<path
						fill='currentColor'
						fillRule='nonzero'
						d='M8.592 4.712a.486.486 0 0 0-.686-.687L6.309 5.623 4.712 4.026a.486.486 0 0 0-.687.687L5.623 6.31 4.026 7.907a.486.486 0 0 0 .687.687L6.31 6.996l1.598 1.596a.486.486 0 0 0 .687-.686L6.996 6.309z'
					/>
				</g>
			</g>
		</svg>
	);
});
export default memo(SvgNoGuidesIcon);
