import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgSidebarGuidesIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 20' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M5.625 5.625a3.124 3.124 0 1 0 6.25 0V5h-6.25zM5.621.746l.004.004c.398.293.93.324 1.363.078l1.18-.672a1.17 1.17 0 0 1 1.164 0l1.18.672c.43.246.965.215 1.363-.078l.004-.004.031-.023.117-.094.614-.492c.109-.09.25-.137.39-.137h.094c.344 0 .625.281.625.625v5a5 5 0 1 1-10 0v-5c0-.344.281-.625.625-.625h.094c.14 0 .281.047.39.137l.61.488.117.094.031.023zM1.887 18.125h13.726a4.69 4.69 0 0 0-4.675-4.375H6.562a4.687 4.687 0 0 0-4.675 4.375M0 18.438a6.56 6.56 0 0 1 6.563-6.563h4.375a6.56 6.56 0 0 1 6.562 6.563v.312c0 .691-.559 1.25-1.25 1.25h-15C.559 20 0 19.441 0 18.75zM16.875 10a4.37 4.37 0 0 1-2.836-1.043q.031-.045.055-.09A6.2 6.2 0 0 0 15 5.625V1.672a4.373 4.373 0 0 1 6.25 3.953A4.373 4.373 0 0 1 16.875 10m6.926 10h-5.387c.211-.367.336-.793.336-1.25v-.312c0-2.372-1.059-4.5-2.727-5.93q.139-.007.278-.008h2.398A6.3 6.3 0 0 1 25 18.8a1.2 1.2 0 0 1-1.2 1.2'
			/>
		</svg>
	);
});
export default memo(SvgSidebarGuidesIcon);
