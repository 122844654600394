import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgAiqIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 10' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='evenodd'
				d='M18.786.33C21.702.33 25 2.387 25 5.053c0 2.726-3.44 4.713-6.342 4.764-.001 0-.109.012-.243-.124l-3.103-3.16.982-1 2.788 2.85q.146.049.305.03c2.185-.271 4.408-1.729 4.408-3.373 0-1.68-2.217-3.156-4.42-3.408a.57.57 0 0 0-.473.177l-7.94 8.103c-.111.114-.36.117-.463.012L7.262 6.62l.979-.996 2.483 2.53.27-.273L18.16.573c.131-.139.424-.243.625-.243M10.974.1 14.298 3.5l-.986 1.007-2.59-2.636-7.32 7.496a.26.26 0 0 1-.185.078H0v-.487c0-3.097 2.4-3.93 2.4-3.93S0 4.196 0 1.136V.597H.61l.16.001h1.114L2.07.6h1.148c.053 0 .103.022.14.06l3.3 3.36L10.496.101a.33.33 0 0 1 .477 0M5.283 5.447c-1.637 0-3.963 1.043-3.963 2.588h1.426zm5.448-2.22 1.764 1.797L10.73 6.82 8.968 5.024zm9.426-.333a.994.994 0 0 1 .99.998.994.994 0 0 1-.98 1.009.994.994 0 0 1-.99-.999.994.994 0 0 1 .98-1.008M2.616 1.888H1.32c0 2.364 3.553 2.706 3.963 2.722zM20.16 3.483a.41.41 0 0 0-.404.416.41.41 0 0 0 .408.413.41.41 0 0 0 .405-.417.41.41 0 0 0-.409-.412'
			/>
		</svg>
	);
});
export default memo(SvgAiqIcon);
