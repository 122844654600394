import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgCameraIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 22' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M9.722 0a2.73 2.73 0 0 0-2.593 1.88l1.113.374-1.113-.373-.42 1.262H3.125C1.401 3.143 0 4.553 0 6.286v12.571C0 20.591 1.401 22 3.125 22h18.75C23.599 22 25 20.59 25 18.857V6.286c0-1.734-1.401-3.143-3.125-3.143h-3.584l-.42-1.262A2.73 2.73 0 0 0 15.278 0zM9.35 2.627c.053-.162.2-.27.37-.27h5.557c.166 0 .318.108.371.27l.684 2.068c.161.48.605.805 1.113.805h4.429c.43 0 .781.354.781.786v12.571a.786.786 0 0 1-.781.786H3.125a.786.786 0 0 1-.781-.786V6.286c0-.432.351-.786.781-.786h4.429c.503 0 .952-.324 1.113-.805zM12.5 18.071a5.46 5.46 0 0 0 4.736-2.75 5.53 5.53 0 0 0 0-5.5 5.46 5.46 0 0 0-4.736-2.75c-3.02 0-5.469 2.463-5.469 5.5s2.449 5.5 5.469 5.5m-3.125-5.5c0-1.735 1.4-3.142 3.125-3.142a3.134 3.134 0 0 1 3.125 3.142c0 1.736-1.4 3.143-3.125 3.143a3.134 3.134 0 0 1-3.125-3.143'
			/>
		</svg>
	);
});
export default memo(SvgCameraIcon);
