import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgFilterFilledIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 22' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M.19 1.125A1.94 1.94 0 0 1 1.953 0H23.05c.757 0 1.44.437 1.763 1.125a1.98 1.98 0 0 1-.254 2.087l-8.931 10.975v6.242c0 .594-.332 1.14-.865 1.405a1.55 1.55 0 0 1-1.636-.148l-3.125-2.357a1.56 1.56 0 0 1-.625-1.257v-3.885L.439 3.207A1.97 1.97 0 0 1 .19 1.125'
			/>
		</svg>
	);
});
export default memo(SvgFilterFilledIcon);
