import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgAngleDownIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 15' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M11.03 14.372a2.045 2.045 0 0 0 2.947 0L24.39 3.66a2.19 2.19 0 0 0 0-3.032 2.045 2.045 0 0 0-2.948 0L12.5 9.826 3.558.634a2.045 2.045 0 0 0-2.948 0 2.19 2.19 0 0 0 0 3.033L11.023 14.38z'
			/>
		</svg>
	);
});
export default memo(SvgAngleDownIcon);
