import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgGoogleLogoColouredIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25' ref={ref} {...props}>
			<g fill='none' fillRule='nonzero'>
				<path fill='#4285F4' d='M25 12.784c0-.886-.081-1.739-.232-2.557H12.755v4.836h6.865c-.296 1.562-1.195 2.886-2.546 3.772v3.137h4.123C23.609 19.795 25 16.59 25 12.784' />
				<path
					fill='#34A853'
					d='M12.755 25c3.444 0 6.331-1.12 8.442-3.028l-4.123-3.137c-1.142.75-2.603 1.193-4.319 1.193-3.322 0-6.134-2.198-7.137-5.153H1.357v3.239C3.455 22.199 7.769 25 12.755 25'
				/>
				<path fill='#FBBC04' d='M5.618 14.875a7.4 7.4 0 0 1-.4-2.375c0-.824.145-1.625.4-2.375V6.886H1.357a12.3 12.3 0 0 0 0 11.228z' />
				<path
					fill='#E94235'
					d='M12.755 4.972c1.873 0 3.554.63 4.876 1.869l3.658-3.585C19.08 1.239 16.193 0 12.755 0 7.77 0 3.455 2.801 1.357 6.886l4.261 3.239c1.003-2.955 3.815-5.153 7.137-5.153'
				/>
			</g>
		</svg>
	);
});
export default memo(SvgGoogleLogoColouredIcon);
