import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgLogoutIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 22' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M17.188 5.608 22.558 11l-5.37 5.392V13.75c0-.653-.523-1.179-1.172-1.179h-5.86V9.43h5.86c.649 0 1.172-.526 1.172-1.179zM25 11c0-.565-.225-1.105-.62-1.503l-5.67-5.686a2.26 2.26 0 0 0-1.6-.668 2.274 2.274 0 0 0-2.266 2.278v1.65h-4.688A2.35 2.35 0 0 0 7.812 9.43v3.142a2.35 2.35 0 0 0 2.344 2.358h4.688v1.65a2.274 2.274 0 0 0 2.265 2.278c.601 0 1.177-.24 1.602-.668l5.669-5.686c.395-.398.62-.938.62-1.503M8.203 2.357c.65 0 1.172-.525 1.172-1.178C9.375.525 8.853 0 8.203 0H4.297C1.924 0 0 1.935 0 4.321V17.68C0 20.065 1.924 22 4.297 22h3.906c.65 0 1.172-.525 1.172-1.179 0-.653-.522-1.178-1.172-1.178H4.297a1.96 1.96 0 0 1-1.953-1.964V4.32c0-1.085.874-1.964 1.953-1.964z'
			/>
		</svg>
	);
});
export default memo(SvgLogoutIcon);
