import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgBaitFlyIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 22' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='evenodd'
				d='M23.479 4.13c-1.058-.84-1.98-.307-1.98-.307s-.762-.35-2.112-.745C14.134.57 11.497-.199 8.58.042 3.972.422.633 3.069.12 3.434c-.835.597 2.787-1.18 6.958-1.931 4.17-.752 8.508.86 9.744 1.772S1.625 4.855 10.63 4.68c3.057-.059 7.43-.494 7.576.543-2.062-.069-10.543.416-12.107 1.692s8.25-1.379 11.154-.6c-1.411.628-5.124 2.653-6.477 3.4-.006-.012-3.18 1.562-3.908 2.647a8 8 0 0 0-.712.732c-3.563 4.229-2.914 7.203-.706 8.61 2.207 1.406 7.21-2.665 7.21-2.665l-2.98.87-1.099-.023.62.526s-4.416 2.48-4.754-2.239c-.13-1.827 1.394-3.49 3.094-4.767.142-.095 2.017-1.351 3.168-1.849l.022-.12c.475-.247.84-.42 1.019-.51.799-.395 7.294-3.665 9.617-4.835l-5.388 4.495c.684-.162 1.518-.67 2.351-1.303l-2.621 2.637c.574-.236 1.257-.725 1.963-1.33l-1.915 2.2c.596-.306 1.348-.97 2.127-1.775l-2.48 3.272c.838-.415 2.232-2.031 3.554-3.745l-2.643 4.373c.946-.715 4.106-5.967 5.393-8.143l-4.185 8.141c.958-.978 2.38-3.744 3.423-5.938l-2.442 6.19c1.935-1.782 3.797-9.165 3.797-9.165 1.463-.725 1.597-1.208 1.597-1.208l.172-.085c.918-.455.807-1.475.807-1.475s.28-.38-.002-.544c-.284-.162-.406.21-.406.21-.22 1.038-.848 1.198-.99 1.23'
			/>
		</svg>
	);
});
export default memo(SvgBaitFlyIcon);
