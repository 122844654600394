import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgSidebarCogIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 25' ref={ref} {...props}>
			<g fill='none' fillRule='evenodd'>
				<path
					fill='currentColor'
					fillRule='nonzero'
					d='M7.963 2.008A2.404 2.404 0 0 1 10.335 0h3.331c1.174 0 2.179.849 2.372 2.008l.273 1.64c.028.163.151.326.338.43.1.053.197.11.323.185.19.115.395.14.552.082l1.562-.584a2.404 2.404 0 0 1 2.926 1.046l1.665 2.881a2.4 2.4 0 0 1-.556 3.06l-1.29 1.063c-.127.103-.206.289-.202.508q.005.182 0 .363c-.004.218.075.403.202.508l1.292 1.062a2.405 2.405 0 0 1 .555 3.06l-1.668 2.88a2.404 2.404 0 0 1-2.922 1.047l-1.564-.584c-.157-.059-.362-.033-.552.08a9 9 0 0 1-.317.184c-.193.107-.316.27-.344.43l-.273 1.641A2.404 2.404 0 0 1 13.666 25h-3.333a2.404 2.404 0 0 1-2.371-2.008l-.274-1.643c-.027-.161-.15-.323-.343-.43a9 9 0 0 1-.315-.182c-.192-.115-.397-.14-.554-.082l-1.564.584a2.404 2.404 0 0 1-2.923-1.046L.323 17.312a2.404 2.404 0 0 1 .556-3.06l1.29-1.062c.127-.103.205-.29.201-.51a10 10 0 0 1 0-.362c.004-.218-.074-.403-.202-.508l-1.29-1.06a2.404 2.404 0 0 1-.555-3.06l1.666-2.881A2.404 2.404 0 0 1 4.914 3.76l1.56.584c.16.059.364.033.552-.08q.155-.093.32-.185c.193-.107.315-.268.342-.43zm5.703-.085h-3.331a.486.486 0 0 0-.481.402l-.275 1.642c-.128.768-.622 1.42-1.31 1.798a7 7 0 0 0-.253.147c-.673.405-1.482.507-2.21.235l-1.563-.585a.49.49 0 0 0-.596.212l-1.666 2.88a.48.48 0 0 0 .113.608l1.289 1.06c.6.492.918 1.245.904 2.032a8 8 0 0 0 0 .291c.014.787-.304 1.542-.904 2.033l-1.29 1.061a.476.476 0 0 0-.112.608l1.666 2.88a.49.49 0 0 0 .596.21l1.564-.584c.727-.272 1.536-.17 2.21.235q.123.075.252.146c.689.38 1.183 1.032 1.31 1.798l.274 1.643c.038.23.241.402.48.402h3.333c.24 0 .443-.172.481-.404l.274-1.643c.13-.765.623-1.416 1.309-1.794q.125-.07.254-.148c.675-.405 1.483-.507 2.21-.235l1.563.584a.49.49 0 0 0 .595-.21l1.667-2.88a.48.48 0 0 0-.112-.608l-1.29-1.061c-.6-.492-.919-1.245-.904-2.032a8 8 0 0 0 0-.291c-.015-.788.304-1.543.904-2.033l1.29-1.061a.48.48 0 0 0 .112-.608l-1.665-2.88a.49.49 0 0 0-.597-.21l-1.564.584c-.726.272-1.537.17-2.208-.235a6 6 0 0 0-.253-.146c-.688-.38-1.182-1.03-1.311-1.798l-.274-1.643a.486.486 0 0 0-.48-.402'
				/>
				<path
					stroke='currentColor'
					strokeLinecap='round'
					strokeLinejoin='round'
					strokeWidth={1.923}
					d='M15.854 12.5A3.85 3.85 0 0 1 12 16.346 3.85 3.85 0 0 1 8.145 12.5 3.85 3.85 0 0 1 12 8.654a3.85 3.85 0 0 1 3.854 3.846'
				/>
			</g>
		</svg>
	);
});
export default memo(SvgSidebarCogIcon);
