import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgNoBookingsIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M18.626.158a.877.877 0 0 1 .216 1.22L2.591 24.627a.874.874 0 0 1-1.217.215.877.877 0 0 1-.216-1.22L17.409.373a.874.874 0 0 1 1.217-.215m-.828 3.02c.163.348.254.736.254 1.145v16.424c0 1.51-1.241 2.737-2.769 2.737H4.207q-.291 0-.564-.057l1.394-1.997h10.246a.69.69 0 0 0 .692-.683V5.784zm-2.515-1.593c.334 0 .654.059.95.166l-1.319 1.886-10.707.001a.69.69 0 0 0-.693.685v15.624L1.898 22.26a2.7 2.7 0 0 1-.462-1.512V4.323c0-1.51 1.242-2.738 2.77-2.738zM9.242 15.418c.302.179.503.505.503.88a1.03 1.03 0 0 1-1.039 1.027h-.799zm-2.46-.147L5.72 16.793a1.03 1.03 0 0 1 .911-1.521zm2.87-4.106-1.435 2.053H6.63a1.03 1.03 0 0 1-1.039-1.026 1.03 1.03 0 0 1 1.039-1.026zm3.208 0a1.03 1.03 0 0 1 1.039 1.027 1.03 1.03 0 0 1-1.039 1.027h-2.082l1.435-2.054zm-.338-4.106-1.435 2.053-4.457.001A1.03 1.03 0 0 1 5.59 8.086 1.03 1.03 0 0 1 6.63 7.06z'
			/>
		</svg>
	);
});
export default memo(SvgNoBookingsIcon);
