import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgStarIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 24' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M12.5 0c.436 0 .834.244 1.024.633l3.25 6.623 7.256 1.059a1.121 1.121 0 0 1 .635 1.912l-5.263 5.166 1.241 7.293c.071.422-.104.848-.46 1.101-.355.253-.819.281-1.198.08l-6.49-3.431-6.48 3.426a1.14 1.14 0 0 1-1.198-.08 1.13 1.13 0 0 1-.46-1.1L5.6 15.387l-5.263-5.16a1.11 1.11 0 0 1-.28-1.149c.133-.403.488-.698.915-.764l7.257-1.06 3.25-6.622A1.14 1.14 0 0 1 12.5 0m0 3.703-2.486 5.071a1.15 1.15 0 0 1-.858.624l-5.604.815 4.07 3.99c.26.257.383.622.321.983l-.961 5.61 4.983-2.633a1.14 1.14 0 0 1 1.07 0l4.984 2.634-.957-5.606c-.061-.36.057-.726.322-.984l4.07-3.989-5.605-.82a1.14 1.14 0 0 1-.857-.624z'
			/>
		</svg>
	);
});
export default memo(SvgStarIcon);
