import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgAngleUpDownIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M10.49.34a1.61 1.61 0 0 0-1.98 0L.596 6.592A1.55 1.55 0 0 0 .348 8.79a1.6 1.6 0 0 0 2.226.244L9.5 3.563l6.926 5.47a1.6 1.6 0 0 0 2.226-.244 1.55 1.55 0 0 0-.247-2.198zm7.915 18.069a1.55 1.55 0 0 0 .247-2.198 1.6 1.6 0 0 0-2.226-.244L9.5 21.437l-6.926-5.47a1.6 1.6 0 0 0-2.226.244 1.55 1.55 0 0 0 .247 2.198l7.916 6.25c.578.455 1.4.455 1.978 0z'
			/>
		</svg>
	);
});
export default memo(SvgAngleUpDownIcon);
