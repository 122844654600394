import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgCheckFilledIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M12.5 25C19.404 25 25 19.404 25 12.5S19.404 0 12.5 0 0 5.596 0 12.5 5.596 25 12.5 25m5.518-14.795-6.25 6.25a1.167 1.167 0 0 1-1.656 0L6.987 13.33a1.171 1.171 0 0 1 1.655-1.655l2.296 2.295 5.42-5.425a1.171 1.171 0 0 1 1.655 1.655z'
			/>
		</svg>
	);
});
export default memo(SvgCheckFilledIcon);
