import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgBarsIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 21' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M0 1.37C0 .61.597 0 1.34 0h22.32C24.404 0 25 .61 25 1.37c0 .759-.597 1.37-1.34 1.37H1.34C.596 2.74 0 2.128 0 1.37m0 9.13c0-.759.597-1.37 1.34-1.37h22.32c.743 0 1.34.611 1.34 1.37s-.597 1.37-1.34 1.37H1.34C.596 11.87 0 11.259 0 10.5m25 9.13c0 .76-.597 1.37-1.34 1.37H1.34C.596 21 0 20.39 0 19.63c0-.759.597-1.37 1.34-1.37h22.32c.743 0 1.34.611 1.34 1.37'
			/>
		</svg>
	);
});
export default memo(SvgBarsIcon);
