import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgGlobeStandIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 21 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M.35 17.806a1.187 1.187 0 0 0 0 1.682 1.187 1.187 0 0 0 1.678 0l.916-.918a11.05 11.05 0 0 0 5.77 2.397v1.652h-4.75c-.658 0-1.188.53-1.188 1.19S3.306 25 3.964 25H15.85c.658 0 1.188-.53 1.188-1.19s-.53-1.191-1.188-1.191h-4.754v-1.652a11.05 11.05 0 0 0 6.655-3.19c4.047-4.054 4.314-10.469.798-14.83l.916-.917a1.187 1.187 0 0 0 0-1.682 1.187 1.187 0 0 0-1.679 0L16.073 2.06a1.187 1.187 0 0 0 0 1.681c3.402 3.409 3.402 8.94 0 12.349a8.71 8.71 0 0 1-12.327 0 1.18 1.18 0 0 0-1.678 0zm4.803-7.888a4.76 4.76 0 0 1 4.754-4.763 4.76 4.76 0 0 1 4.754 4.763 4.76 4.76 0 0 1-4.754 4.763 4.76 4.76 0 0 1-4.754-4.763m11.885 0c0-2.552-1.359-4.91-3.565-6.187a7.12 7.12 0 0 0-7.132 0 7.15 7.15 0 0 0-3.565 6.187c0 3.946 3.193 7.144 7.131 7.144s7.131-3.198 7.131-7.144'
			/>
		</svg>
	);
});
export default memo(SvgGlobeStandIcon);
