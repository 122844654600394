import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgSidebarLogLinkIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25' ref={ref} {...props}>
			<g fill='currentColor' fillRule='nonzero'>
				<path d='M13.077 8.17a.926.926 0 0 1 1.235-.437 6.268 6.268 0 0 1 1.73 10.09L10.7 23.163A6.268 6.268 0 1 1 1.836 14.3l2.086-2.086a.926.926 0 0 1 1.309 1.31L3.145 15.61a4.416 4.416 0 1 0 6.245 6.245l5.342-5.342a4.416 4.416 0 0 0-1.218-7.109.926.926 0 0 1-.437-1.235' />
				<path d='M14.3 1.836a6.268 6.268 0 0 1 8.864 8.864l-2.086 2.086a.926.926 0 0 1-1.309-1.31l2.086-2.086a4.416 4.416 0 0 0-6.245-6.245l-5.342 5.342a4.416 4.416 0 0 0 1.218 7.109.926.926 0 0 1-.798 1.671 6.268 6.268 0 0 1-1.73-10.09z' />
			</g>
		</svg>
	);
});
export default memo(SvgSidebarLogLinkIcon);
