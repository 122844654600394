import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgEnvelopeIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 19' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M3.125 2.375a.79.79 0 0 0-.781.792V4.26l8.423 7.006c1.01.842 2.46.842 3.471 0l8.418-7.006V3.167a.79.79 0 0 0-.781-.792zm-.781 4.958v8.5c0 .436.351.792.781.792h18.75c.43 0 .781-.356.781-.792v-8.5l-6.933 5.77a5.03 5.03 0 0 1-6.446 0zM0 3.167C0 1.42 1.401 0 3.125 0h18.75C23.599 0 25 1.42 25 3.167v12.666C25 17.58 23.599 19 21.875 19H3.125C1.401 19 0 17.58 0 15.833z'
			/>
		</svg>
	);
});
export default memo(SvgEnvelopeIcon);
