import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgKeyIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M10.154 8.594a6.249 6.249 0 1 1 12.498 0 6.25 6.25 0 0 1-7.718 6.074 1.16 1.16 0 0 0-1.104.312l-1.425 1.426H9.764c-.65 0-1.172.523-1.172 1.172v1.953H6.64c-.649 0-1.171.523-1.171 1.172v1.953H2.343v-3.813l7.675-7.676c.288-.288.405-.708.312-1.104a6.3 6.3 0 0 1-.176-1.47M16.403 0a8.593 8.593 0 0 0-8.485 9.956L.342 17.53c-.22.22-.342.518-.342.83v5.47C0 24.477.522 25 1.172 25h5.467c.65 0 1.172-.522 1.172-1.172v-1.953h1.953c.649 0 1.171-.522 1.171-1.172V18.75h1.953c.313 0 .61-.122.83-.342l1.328-1.328A8.593 8.593 0 1 0 16.403 0m1.562 8.594a1.562 1.562 0 1 0 0-3.125 1.562 1.562 0 0 0 0 3.125'
			/>
		</svg>
	);
});
export default memo(SvgKeyIcon);
