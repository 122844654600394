import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgXFilledIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M12.5 25C19.404 25 25 19.404 25 12.5S19.404 0 12.5 0 0 5.596 0 12.5 5.596 25 12.5 25M8.545 8.545a1.167 1.167 0 0 1 1.655 0l2.295 2.295 2.295-2.295a1.171 1.171 0 0 1 1.655 1.655l-2.295 2.295 2.295 2.295a1.171 1.171 0 0 1-1.655 1.655l-2.295-2.295-2.295 2.295a1.171 1.171 0 0 1-1.655-1.655l2.295-2.295L8.545 10.2a1.167 1.167 0 0 1 0-1.655'
			/>
		</svg>
	);
});
export default memo(SvgXFilledIcon);
