import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgAtIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M12.5 2.344C6.89 2.344 2.344 6.89 2.344 12.5S6.89 22.656 12.5 22.656c.65 0 1.172.523 1.172 1.172A1.17 1.17 0 0 1 12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5v1.367a4.493 4.493 0 0 1-8.169 2.578A5.858 5.858 0 0 1 6.641 12.5 5.86 5.86 0 0 1 12.5 6.64c1.406 0 2.695.494 3.701 1.319.21-.322.571-.537.986-.537.65 0 1.172.522 1.172 1.172v5.273a2.148 2.148 0 0 0 4.297 0V12.5c0-5.61-4.546-10.156-10.156-10.156M16.016 12.5a3.516 3.516 0 1 0-7.032 0 3.516 3.516 0 0 0 7.032 0'
			/>
		</svg>
	);
});
export default memo(SvgAtIcon);
