import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgExclamationFilledIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M12.5 25C19.404 25 25 19.404 25 12.5S19.404 0 12.5 0 0 5.596 0 12.5 5.596 25 12.5 25m0-18.75c.65 0 1.172.522 1.172 1.172v5.469a1.17 1.17 0 0 1-1.172 1.172 1.17 1.17 0 0 1-1.172-1.172V7.42c0-.649.523-1.171 1.172-1.171m-1.562 10.938a1.563 1.563 0 1 1 3.125 0 1.563 1.563 0 0 1-3.126 0'
			/>
		</svg>
	);
});
export default memo(SvgExclamationFilledIcon);
