import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgSidebarDashboardIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M12.5 2.344c5.61 0 10.156 4.547 10.156 10.156S18.11 22.656 12.5 22.656 2.344 18.11 2.344 12.5 6.89 2.344 12.5 2.344M12.5 25C19.404 25 25 19.404 25 12.5S19.404 0 12.5 0 0 5.596 0 12.5 5.596 25 12.5 25m1.563-19.531a1.563 1.563 0 1 0-3.126 0 1.563 1.563 0 0 0 3.126 0M12.5 19.922a2.736 2.736 0 0 0 2.734-2.735c0-.683-.249-1.308-.669-1.787l3.306-7.504a1.173 1.173 0 0 0-.6-1.543 1.173 1.173 0 0 0-1.543.6l-3.306 7.5a2.736 2.736 0 0 0 .078 5.469M9.375 7.812a1.563 1.563 0 1 0-3.125 0 1.563 1.563 0 0 0 3.125 0m-3.906 6.25a1.563 1.563 0 1 0 0-3.125 1.563 1.563 0 0 0 0 3.126M21.094 12.5a1.563 1.563 0 1 0-3.125 0 1.563 1.563 0 0 0 3.125 0'
			/>
		</svg>
	);
});
export default memo(SvgSidebarDashboardIcon);
