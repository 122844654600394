import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgCartPlusIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 20' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M.938 0A.935.935 0 0 0 0 .938c0 .519.418.937.938.937h1.777c.148 0 .277.105.308.254L5.04 12.715A2.81 2.81 0 0 0 7.801 15h4.726a6.952 6.952 0 0 1 .086-1.875H7.801a.94.94 0 0 1-.922-.762l-.211-1.113h6.582a6.9 6.9 0 0 1 1.406-1.875H6.313L5.12 3.125H20.34l-1.18 4.379c.07-.004.145-.004.215-.004q.868.002 1.672.203l1.207-4.484a1.562 1.562 0 0 0-1.508-1.969H4.691A2.18 2.18 0 0 0 2.715 0zm5.937 20a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75m6.875-5.625a5.625 5.625 0 1 0 11.25 0 5.625 5.625 0 0 0-11.25 0m8.125.625H20v1.875a.627.627 0 0 1-.625.625.627.627 0 0 1-.625-.625V15h-1.875a.627.627 0 0 1-.625-.625c0-.344.281-.625.625-.625h1.875v-1.875c0-.344.281-.625.625-.625s.625.281.625.625v1.875h1.875c.344 0 .625.281.625.625a.627.627 0 0 1-.625.625'
			/>
		</svg>
	);
});
export default memo(SvgCartPlusIcon);
