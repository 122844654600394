import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgPermitsIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 18' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M.938 2.571c.519 0 .937.43.937.965v9.643c0 1.599 1.258 2.892 2.813 2.892h15.625c.519 0 .937.43.937.965a.95.95 0 0 1-.937.964H4.688C2.098 18 0 15.842 0 13.179V3.536C0 3 .418 2.57.938 2.57M22.5 0C23.879 0 25 1.153 25 2.571v2.25c0 .354-.293.631-.61.768a1.61 1.61 0 0 0-.953 1.482c0 .667.395 1.238.954 1.483.32.137.609.414.609.767v2.25c0 1.419-1.121 2.572-2.5 2.572H6.25c-1.379 0-2.5-1.153-2.5-2.572v-9C3.75 1.153 4.871 0 6.25 0Zm0 1.929H6.25a.636.636 0 0 0-.625.642v9c0 .354.281.643.625.643H22.5c.344 0 .625-.289.625-.643v-1.534a3.56 3.56 0 0 1-1.562-2.966c0-1.245.625-2.334 1.562-2.965V2.571a.636.636 0 0 0-.625-.642m-3.125 8.357c.345 0 .625.288.625.643s-.28.642-.625.642a.634.634 0 0 1-.625-.642c0-.355.28-.643.625-.643m-6.48-6.429c1.019 0 1.868.468 2.498 1.01a5.6 5.6 0 0 1 1.3 1.637.54.54 0 0 1 0 .48 5.6 5.6 0 0 1-1.3 1.638c-.63.543-1.48 1.01-2.497 1.01l-.07-.002-.134.073q-1.239.659-1.564.573-.342-.09.129-1.063a4.7 4.7 0 0 1-.858-.59c-.26-.224-.51-.656-.687-.705q-.177-.05-1.439.855a.51.51 0 0 1-.621-.08.53.53 0 0 1-.084-.634q.727-1.125.727-1.314T7.568 5.43a.53.53 0 0 1 .085-.634c.166-.167.42-.2.622-.08q1.305.953 1.437.88c.132-.074.429-.504.689-.73.627-.542 1.477-1.01 2.495-1.01m6.48 3.857c.345 0 .625.288.625.643S19.72 9 19.375 9a.634.634 0 0 1-.625-.643c0-.355.28-.643.625-.643M14.18 6.22a.52.52 0 0 0-.513.525c0 .29.23.525.513.525a.52.52 0 0 0 .514-.525.52.52 0 0 0-.514-.525m5.195-1.077c.345 0 .625.288.625.643s-.28.643-.625.643a.634.634 0 0 1-.625-.643c0-.355.28-.643.625-.643m0-2.572c.345 0 .625.288.625.643s-.28.643-.625.643a.634.634 0 0 1-.625-.643c0-.355.28-.643.625-.643'
			/>
		</svg>
	);
});
export default memo(SvgPermitsIcon);
