import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgSidebarPermitsOverviewIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 22' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M24.414 2.799a1.58 1.58 0 0 0 .244-2.21 1.56 1.56 0 0 0-2.197-.245l-6.865 5.524-5.284-3.982a1.554 1.554 0 0 0-1.914.029L.586 8.201a1.58 1.58 0 0 0-.244 2.21 1.56 1.56 0 0 0 2.197.245l6.865-5.524 5.284 3.982a1.554 1.554 0 0 0 1.914-.03zm-16.602 8.2v9.43c0 .869.699 1.571 1.563 1.571s1.562-.702 1.562-1.571v-9.43c0-.868-.698-1.57-1.562-1.57s-1.563.702-1.563 1.57m-6.25 4.715v4.715c0 .869.699 1.571 1.563 1.571s1.562-.702 1.562-1.571v-4.715c0-.869-.698-1.571-1.562-1.571s-1.563.702-1.563 1.571m14.063-3.143c-.864 0-1.562.703-1.562 1.572v6.286c0 .869.698 1.571 1.562 1.571s1.563-.702 1.563-1.571v-6.286c0-.87-.699-1.572-1.563-1.572M20.313 11v9.429c0 .869.698 1.571 1.562 1.571s1.563-.702 1.563-1.571v-9.43c0-.868-.699-1.57-1.563-1.57s-1.562.702-1.562 1.57'
			/>
		</svg>
	);
});
export default memo(SvgSidebarPermitsOverviewIcon);
