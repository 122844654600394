import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgPlusIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M14.423 1.923C14.423.86 13.563 0 12.5 0s-1.923.86-1.923 1.923v8.654H1.923C.86 10.577 0 11.437 0 12.5s.86 1.923 1.923 1.923h8.654v8.654c0 1.064.86 1.923 1.923 1.923s1.923-.86 1.923-1.923v-8.654h8.654c1.064 0 1.923-.86 1.923-1.923s-.86-1.923-1.923-1.923h-8.654z'
			/>
		</svg>
	);
});
export default memo(SvgPlusIcon);
