import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgPenIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='m2.36 16.704-1.035 3.513-.557 1.897a1.08 1.08 0 0 0 1.335 1.345l1.903-.562 3.512-1.035a3.94 3.94 0 0 0 1.502-.827q.093-.082.18-.17L22.88 7.188a2.88 2.88 0 0 0 0-4.07l-1.772-1.777a2.877 2.877 0 0 0-4.07 0l-13.68 13.68a4 4 0 0 0-.998 1.682m2.068.607c.077-.251.203-.485.378-.683l.077-.081L15.158 6.276l2.789 2.788L7.675 19.34a1.8 1.8 0 0 1-.764.454l-1.052.31-2.465.724.724-2.464z'
			/>
		</svg>
	);
});
export default memo(SvgPenIcon);
