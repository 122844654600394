import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgSidebarBookingsIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M3.167 2.344a.79.79 0 0 0-.792.781v18.75c0 .43.356.781.792.781h12.666a.79.79 0 0 0 .792-.781V3.125a.79.79 0 0 0-.792-.781zM0 3.125C0 1.401 1.42 0 3.167 0h12.666C17.58 0 19 1.401 19 3.125v18.75C19 23.599 17.58 25 15.833 25H3.167C1.42 25 0 23.599 0 21.875zM5.938 6.25h7.125c.658 0 1.187.522 1.187 1.172s-.53 1.172-1.187 1.172H5.937A1.177 1.177 0 0 1 4.75 7.422c0-.65.53-1.172 1.188-1.172m0 4.688h7.125c.658 0 1.187.522 1.187 1.171 0 .65-.53 1.172-1.187 1.172H5.937A1.177 1.177 0 0 1 4.75 12.11c0-.649.53-1.171 1.188-1.171m0 4.687h2.375c.658 0 1.187.522 1.187 1.172s-.53 1.172-1.187 1.172H5.937a1.177 1.177 0 0 1-1.187-1.172c0-.65.53-1.172 1.188-1.172'
			/>
		</svg>
	);
});
export default memo(SvgSidebarBookingsIcon);
