import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgExclamationTriangleFilledIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 22' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M12.5 0c.693 0 1.332.368 1.684.972L24.73 19.044a1.97 1.97 0 0 1 .01 1.969 1.96 1.96 0 0 1-1.694.987H1.95a1.96 1.96 0 0 1-1.694-.987 1.98 1.98 0 0 1 .01-1.97L10.814.974A1.95 1.95 0 0 1 12.5 0m0 6.286c-.65 0-1.173.525-1.173 1.178v5.5a1.172 1.172 0 1 0 2.344 0v-5.5c0-.653-.522-1.178-1.172-1.178m1.562 11c0-.868-.7-1.572-1.563-1.572s-1.563.704-1.563 1.572.7 1.571 1.563 1.571 1.563-.703 1.563-1.571'
			/>
		</svg>
	);
});
export default memo(SvgExclamationTriangleFilledIcon);
