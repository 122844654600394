import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgCatchFilledIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 17' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M14.584 0c2.75 0 5.047 1.237 6.748 2.668 1.697 1.432 2.903 3.142 3.515 4.33.204.4.204.873 0 1.272-.612 1.189-1.818 2.899-3.515 4.33-1.701 1.437-3.997 2.673-6.748 2.673l-.194-.004-.117.063q-3.556 1.876-4.467 1.642-.926-.24.347-2.81a12.5 12.5 0 0 1-2.318-1.56c-.703-.594-1.38-1.735-1.857-1.865q-.477-.13-3.889 2.26a1.39 1.39 0 0 1-1.679-.212 1.386 1.386 0 0 1-.226-1.675Q2.15 8.135 2.148 7.637q0-.5-1.964-3.476a1.391 1.391 0 0 1 1.91-1.887q3.525 2.52 3.884 2.325c.358-.195 1.159-1.332 1.862-1.926C9.536 1.237 11.832 0 14.584 0m3.471 6.248a1.389 1.389 0 1 0 0 2.777 1.389 1.389 0 0 0 0-2.777'
			/>
		</svg>
	);
});
export default memo(SvgCatchFilledIcon);
