import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgXIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M24.45 3.206a1.867 1.867 0 0 0 0-2.648 1.874 1.874 0 0 0-2.648 0l-9.294 9.294L3.206.551a1.867 1.867 0 0 0-2.648 0 1.874 1.874 0 0 0 0 2.647l9.294 9.294-9.301 9.302a1.867 1.867 0 0 0 0 2.648 1.874 1.874 0 0 0 2.647 0l9.294-9.294 9.302 9.301a1.867 1.867 0 0 0 2.648 0 1.874 1.874 0 0 0 0-2.647l-9.294-9.294z'
			/>
		</svg>
	);
});
export default memo(SvgXIcon);
