interface SpinnerProps {
	className?: string;
}

export default function Spinner({ className = '' }: SpinnerProps) {
	const _className = `${!className ? 'text-neutral-500 w-10 h-10' : ''} ${className}`;

	return (
		<svg className={_className} stroke='currentColor' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'>
			<g fill='none' fillRule='evenodd'>
				<g strokeWidth='2' transform='translate(1 1)'>
					<circle cx='18' cy='18' r='18' strokeOpacity='.5' />
					<path d='M36 18c0-9.94-8.06-18-18-18'>
						<animateTransform attributeName='transform' dur='0.7s' from='0 18 18' repeatCount='indefinite' to='360 18 18' type='rotate' />
					</path>
				</g>
			</g>
		</svg>
	);
}

export function PageSpinner() {
	return (
		<div className='flex items-center text-primary justify-center w-full py-8'>
			<Spinner />
		</div>
	);
}
