import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgUserIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M14.929 6.25c0-2.157-1.76-3.906-3.929-3.906-2.17 0-3.929 1.749-3.929 3.906 0 1.396.75 2.685 1.965 3.383a3.95 3.95 0 0 0 3.928 0A3.9 3.9 0 0 0 14.93 6.25m-10.215 0C4.714 2.798 7.528 0 11 0s6.286 2.798 6.286 6.25S14.472 12.5 11 12.5 4.714 9.702 4.714 6.25M2.421 22.656h17.158c-.437-3.09-3.108-5.468-6.335-5.468H8.756c-3.227 0-5.898 2.377-6.335 5.468M0 23.55c0-4.81 3.919-8.706 8.756-8.706h4.488c4.837 0 8.756 3.896 8.756 8.706 0 .8-.653 1.45-1.458 1.45H1.458C.653 25 0 24.35 0 23.55'
			/>
		</svg>
	);
});
export default memo(SvgUserIcon);
