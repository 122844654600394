import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgSidebarAccountingIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 20' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M20 13.147V1.982l-6.562.965V14.55zm-8.437 1.404V2.947L5 1.982v11.165zM20.444.014a1.248 1.248 0 0 1 1.43 1.239v12.4c0 .592-.414 1.102-.988 1.227l-7.864 1.686c-.343.074-.699.074-1.043 0L4.117 14.88a1.25 1.25 0 0 1-.992-1.227v-12.4c0-.765.676-1.353 1.43-1.239L12.5 1.182zM1.496.857l.399.078q-.019.159-.02.318v14.701l10.383 2.137a1.2 1.2 0 0 0 .484 0l10.383-2.137V1.252q-.001-.164-.02-.317l.399-.078A1.25 1.25 0 0 1 25 2.084v14.384a1.25 1.25 0 0 1-1 1.227l-10.883 2.242q-.305.064-.617.063-.311 0-.617-.063L1 17.695a1.25 1.25 0 0 1-1-1.227V2.084A1.25 1.25 0 0 1 1.496.857'
			/>
		</svg>
	);
});
export default memo(SvgSidebarAccountingIcon);
