import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgSidebarPermitsIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M6.286 0c.869 0 1.571.698 1.571 1.563v1.562h6.286V1.563c0-.865.702-1.563 1.571-1.563.87 0 1.572.698 1.572 1.563v1.562h2.357A2.35 2.35 0 0 1 22 5.469v2.343H0V5.47a2.35 2.35 0 0 1 2.357-2.344h2.357V1.563C4.714.698 5.417 0 6.286 0M0 9.375h22v13.281A2.35 2.35 0 0 1 19.643 25H2.357A2.35 2.35 0 0 1 0 22.656zm3.143 3.906v1.563c0 .43.353.781.786.781H5.5a.786.786 0 0 0 .786-.781V13.28A.786.786 0 0 0 5.5 12.5H3.929a.786.786 0 0 0-.786.781m6.286 0v1.563c0 .43.353.781.785.781h1.572a.786.786 0 0 0 .785-.781V13.28a.786.786 0 0 0-.785-.781h-1.572a.786.786 0 0 0-.785.781M16.5 12.5a.786.786 0 0 0-.786.781v1.563c0 .43.354.781.786.781h1.571a.786.786 0 0 0 .786-.781V13.28a.786.786 0 0 0-.786-.781zM3.143 19.531v1.563c0 .43.353.781.786.781H5.5a.786.786 0 0 0 .786-.781V19.53a.786.786 0 0 0-.786-.781H3.929a.786.786 0 0 0-.786.781m7.071-.781a.786.786 0 0 0-.785.781v1.563c0 .43.353.781.785.781h1.572a.786.786 0 0 0 .785-.781V19.53a.786.786 0 0 0-.785-.781zm5.5.781v1.563c0 .43.354.781.786.781h1.571a.786.786 0 0 0 .786-.781V19.53a.786.786 0 0 0-.786-.781H16.5a.786.786 0 0 0-.786.781'
			/>
		</svg>
	);
});
export default memo(SvgSidebarPermitsIcon);
