import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgPermitIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 15' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M22.5 0C23.879 0 25 1.121 25 2.5v2.395c0 .437-.5.73-.937.73a1.875 1.875 0 0 0 0 3.75c.437 0 .937.293.937.73V12.5c0 1.379-1.121 2.5-2.5 2.5h-20A2.5 2.5 0 0 1 0 12.5v-10C0 1.121 1.121 0 2.5 0Zm0 1.875h-20a.627.627 0 0 0-.625.625v10c0 .344.281.625.625.625h20a.627.627 0 0 0 .625-.625v-1.367a3.752 3.752 0 0 1 0-7.266V2.5a.627.627 0 0 0-.625-.625m-5.625 8.75a.625.625 0 1 1 0 1.25.625.625 0 0 1 0-1.25M9.895 4.25c1.019 0 1.868.455 2.498.981a5.5 5.5 0 0 1 1.3 1.592.51.51 0 0 1 0 .467 5.5 5.5 0 0 1-1.3 1.592c-.63.529-1.48.983-2.497.983l-.07-.002-.134.07q-1.239.64-1.564.557-.342-.087.129-1.034a4.6 4.6 0 0 1-.858-.572c-.26-.218-.51-.638-.687-.686q-.177-.048-1.439.831a.52.52 0 0 1-.621-.078.51.51 0 0 1-.084-.616q.727-1.094.727-1.277 0-.184-.727-1.278a.51.51 0 0 1 .085-.616.52.52 0 0 1 .622-.078q1.305.927 1.437.855c.132-.072.429-.49.689-.708.627-.528 1.477-.983 2.495-.983m6.98 3.875a.625.625 0 1 1 0 1.25.625.625 0 0 1 0-1.25M11.18 6.547a.51.51 0 0 0-.513.51c0 .282.23.511.513.511a.51.51 0 0 0 .514-.51c0-.282-.23-.51-.514-.51m5.695-.922a.625.625 0 1 1 0 1.25.625.625 0 0 1 0-1.25m0-2.5a.625.625 0 1 1 0 1.25.625.625 0 0 1 0-1.25'
			/>
		</svg>
	);
});
export default memo(SvgPermitIcon);
