import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgLogbookIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25' ref={ref} {...props}>
			<g fill='none' fillRule='evenodd'>
				<rect width={24.68} height={24.68} x={0.16} y={0.16} fill='#FFF' stroke='#E0E0E0' strokeWidth={0.32} rx={2.24} />
				<g fill='#0190FF'>
					<g transform='translate(5.128 12.5)'>
						<rect width={11.549} height={1.02} x={3.194} rx={0.51} />
						<rect width={2.015} height={1.02} rx={0.51} />
					</g>
					<g transform='translate(5.128 16.117)'>
						<rect width={11.549} height={1.02} x={3.194} rx={0.51} />
						<rect width={2.015} height={1.02} rx={0.51} />
					</g>
					<g transform='translate(5.128 19.735)'>
						<rect width={11.549} height={1.02} x={3.194} rx={0.51} />
						<rect width={2.015} height={1.02} rx={0.51} />
					</g>
				</g>
				<g fill='#0190FF'>
					<path d='M16.428 4.42c1.754 0 3.738 1.226 3.738 2.815 0 1.625-2.069 2.81-3.815 2.84 0 0-.065.007-.146-.074L14.34 8.118l.59-.596 1.677 1.698q.088.03.184.018c1.314-.161 2.652-1.03 2.652-2.01 0-1.002-1.335-1.881-2.66-2.031a.34.34 0 0 0-.284.105l-4.776 4.83c-.067.067-.217.07-.279.007l-1.946-1.97.588-.593 1.494 1.508.162-.163 4.311-4.356a.6.6 0 0 1 .376-.145m-4.986-.136a.2.2 0 0 1 .287 0l2 2.026-.594.6-1.557-1.571-4.403 4.467a.16.16 0 0 1-.111.047H5.128v-.29c0-1.846 1.443-2.342 1.443-2.342S5.13 6.724 5.13 4.9V4.58h1.304l.632.001c.031 0 .062.013.084.035L9.133 6.62ZM8.306 7.47 6.78 9.013h-.858c0-.92 1.4-1.543 2.384-1.543M6.702 5.35 8.306 6.97c-.246-.01-2.384-.213-2.384-1.622z' />
					<path d='M17.253 5.948a.595.595 0 0 1 .595.596c.002.33-.262.599-.589.6a.595.595 0 0 1-.595-.594.595.595 0 0 1 .59-.602m.002.352a.246.246 0 0 0-.244.248c.001.136.111.246.246.246a.246.246 0 0 0 .244-.249.246.246 0 0 0-.246-.245M11.583 8.29l-1.06-1.072 1.06-1.071 1.061 1.071z' />
				</g>
			</g>
		</svg>
	);
});
export default memo(SvgLogbookIcon);
