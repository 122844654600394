import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgRulerIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M2.681 18.78a.82.82 0 0 1 0-1.152l2.007-2.006 1.533 1.533a.82.82 0 0 0 1.15 0 .82.82 0 0 0 0-1.151L5.84 14.47l2.108-2.109 1.533 1.533a.82.82 0 0 0 1.151 0 .82.82 0 0 0 0-1.15l-1.533-1.533 2.108-2.109 1.533 1.533a.82.82 0 0 0 1.151 0 .82.82 0 0 0 0-1.15l-1.533-1.534 2.109-2.108 1.533 1.533a.82.82 0 0 0 1.15 0 .82.82 0 0 0 0-1.151l-1.532-1.533 2.006-2.007a.82.82 0 0 1 1.151 0l3.54 3.54a.82.82 0 0 1 0 1.15L7.372 22.32a.82.82 0 0 1-1.151 0zm1.808 5.265a3.26 3.26 0 0 0 4.61 0L24.044 9.103a3.26 3.26 0 0 0 0-4.609L20.511.954a3.26 3.26 0 0 0-4.61 0L.956 15.898a3.26 3.26 0 0 0 0 4.609l3.54 3.54z'
			/>
		</svg>
	);
});
export default memo(SvgRulerIcon);
