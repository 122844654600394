import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgFileContractIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M2.375 21.875V3.125c0-.43.356-.781.792-.781h7.916V6.25c0 .864.708 1.563 1.584 1.563h3.958v14.062c0 .43-.356.781-.792.781H3.167a.79.79 0 0 1-.792-.781M3.167 0C1.42 0 0 1.401 0 3.125v18.75C0 23.599 1.42 25 3.167 25h12.666C17.58 25 19 23.599 19 21.875V7.544c0-.83-.332-1.626-.925-2.212L13.592.913A3.18 3.18 0 0 0 11.355 0zM4.75 4.688a.79.79 0 0 0-.792.78c0 .43.357.782.792.782h3.958a.79.79 0 0 0 .792-.781.79.79 0 0 0-.792-.782zm0 3.125a.79.79 0 0 0-.792.78c0 .43.357.782.792.782h3.958a.79.79 0 0 0 .792-.781.79.79 0 0 0-.792-.781zm2.682 10.83.485-1.602.826 2.715a.78.78 0 0 0 .688.552.8.8 0 0 0 .777-.43l.524-1.04a.17.17 0 0 1 .153-.093.17.17 0 0 1 .154.093l.524 1.04c.134.264.411.43.708.43h1.979a.79.79 0 0 0 .792-.782.79.79 0 0 0-.792-.78h-1.49l-.306-.606a1.76 1.76 0 0 0-1.569-.957c-.425 0-.83.151-1.143.42l-.559-1.832a1.32 1.32 0 0 0-1.266-.927c-.584 0-1.099.376-1.267.932l-.737 2.417a.79.79 0 0 1-.757.557H4.75a.79.79 0 0 0-.792.781c0 .43.357.782.792.782h.406a2.37 2.37 0 0 0 2.276-1.67'
			/>
		</svg>
	);
});
export default memo(SvgFileContractIcon);
