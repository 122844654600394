import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgArrowUpDownIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 22' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M7.356.462a1.56 1.56 0 0 0-2.211 0L.458 5.176a1.58 1.58 0 0 0 0 2.224c.61.614 1.601.614 2.211 0l2.022-2.033V20.43c0 .869.698 1.571 1.562 1.571s1.562-.702 1.562-1.571V5.367L9.837 7.4c.61.614 1.601.614 2.211 0a1.58 1.58 0 0 0 0-2.224L7.361.462zm17.186 16.367a1.58 1.58 0 0 0 0-2.225 1.56 1.56 0 0 0-2.211 0l-2.017 2.029V1.57C20.314.702 19.616 0 18.752 0s-1.563.702-1.563 1.571v15.062L15.17 14.6a1.56 1.56 0 0 0-2.213 0 1.58 1.58 0 0 0 0 2.224l4.688 4.714c.61.614 1.6.614 2.211 0l4.687-4.714z'
			/>
		</svg>
	);
});
export default memo(SvgArrowUpDownIcon);
