import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgEllipsisFilledIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25' ref={ref} {...props}>
			<path
				fill='#627D98'
				fillRule='nonzero'
				d='M12.5 25C19.404 25 25 19.404 25 12.5S19.404 0 12.5 0 0 5.596 0 12.5 5.596 25 12.5 25M7.813 10.938a1.563 1.563 0 1 1 0 3.125 1.563 1.563 0 0 1 0-3.126m3.125 1.562a1.563 1.563 0 1 1 3.125 0 1.563 1.563 0 0 1-3.126 0m6.25-1.562a1.563 1.563 0 1 1 0 3.125 1.563 1.563 0 0 1 0-3.126'
			/>
		</svg>
	);
});
export default memo(SvgEllipsisFilledIcon);
