import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgTrashIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='m8.325 2.52-.928 1.386h7.08L13.55 2.52a.4.4 0 0 0-.327-.176H8.647a.39.39 0 0 0-.327.176zm7.178-1.3 1.792 2.686h3.408c.65 0 1.172.523 1.172 1.172a1.17 1.17 0 0 1-1.172 1.172h-.39v14.844A3.905 3.905 0 0 1 16.406 25H5.47a3.905 3.905 0 0 1-3.907-3.906V6.25h-.39A1.17 1.17 0 0 1 0 5.078c0-.65.522-1.172 1.172-1.172H4.58l1.792-2.69A2.75 2.75 0 0 1 8.647 0h4.576c.913 0 1.767.459 2.275 1.216zM3.906 6.25v14.844a1.56 1.56 0 0 0 1.563 1.562h10.937a1.56 1.56 0 0 0 1.563-1.562V6.25zm3.907 3.125v10.156c0 .43-.352.782-.782.782a.784.784 0 0 1-.781-.782V9.375c0-.43.352-.781.781-.781s.782.351.782.781m3.906 0v10.156c0 .43-.352.782-.781.782a.784.784 0 0 1-.782-.782V9.375c0-.43.352-.781.781-.781s.782.351.782.781m3.906 0v10.156c0 .43-.352.782-.781.782a.784.784 0 0 1-.781-.782V9.375c0-.43.351-.781.78-.781s.782.351.782.781'
			/>
		</svg>
	);
});
export default memo(SvgTrashIcon);
