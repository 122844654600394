import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgWaterwayMapIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 22' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M17.708 5.156c0 .37-.078.786-.217 1.229-.247.79-.677 1.667-1.189 2.543l-.004.01q-.203.346-.417.687c-.19.3-.386.601-.586.893l-.017.026a43 43 0 0 1-2.136 2.862.822.822 0 0 1-1.284 0 44 44 0 0 1-2.136-2.862c-.798-1.173-1.575-2.47-2.027-3.626-.034-.09-.07-.18-.1-.271-.19-.541-.303-1.048-.303-1.487q.001-.303.034-.593C7.622 1.994 9.826 0 12.5 0c2.878 0 5.208 2.307 5.208 5.156m-2.43 7.76a39 39 0 0 0 1.853-2.681q.118-.187.23-.378v9.637l5.556-1.998V6.63l-4.718 1.697c.095-.202.19-.4.273-.602.256-.601.478-1.254.573-1.899l4.557-1.641c.322-.116.678-.069.955.125.278.193.443.507.443.846v13.062c0 .434-.273.82-.686.97l-7.639 2.75c-.23.082-.481.082-.711 0L8.68 19.315l-7.283 2.62a1.05 1.05 0 0 1-.955-.124A1.03 1.03 0 0 1 0 20.968V7.906c0-.434.273-.82.686-.971l5.217-1.878v.099c0 .649.16 1.32.386 1.96L2.083 8.627v10.866l5.556-1.998V9.861q.115.192.23.378a38 38 0 0 0 1.853 2.681v4.576l5.556 1.998zM12.5 6.53c.959 0 1.736-.77 1.736-1.719S13.46 3.094 12.5 3.094a1.74 1.74 0 0 0-1.504.859 1.7 1.7 0 0 0 0 1.719c.31.531.884.859 1.504.859'
			/>
		</svg>
	);
});
export default memo(SvgWaterwayMapIcon);
