import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgInfoCircleIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M12.5 2.344c5.61 0 10.156 4.547 10.156 10.156S18.11 22.656 12.5 22.656 2.344 18.11 2.344 12.5 6.89 2.344 12.5 2.344M12.5 25C19.404 25 25 19.404 25 12.5S19.404 0 12.5 0 0 5.596 0 12.5 5.596 25 12.5 25m-1.953-8.594a1.17 1.17 0 0 0-1.172 1.172c0 .65.522 1.172 1.172 1.172h3.906a1.17 1.17 0 0 0 1.172-1.172 1.17 1.17 0 0 0-1.172-1.172h-.39V12.11a1.17 1.17 0 0 0-1.172-1.171h-2.344a1.17 1.17 0 0 0-1.172 1.171c0 .65.522 1.172 1.172 1.172h1.172v3.125zM12.5 9.375a1.563 1.563 0 1 0 0-3.125 1.563 1.563 0 0 0 0 3.125'
			/>
		</svg>
	);
});
export default memo(SvgInfoCircleIcon);
