"use client"

import * as React from "react"
import { AngleLeftIcon, AngleRightIcon } from "@aiq/icons/components"
import { DayPicker, DayPickerProps } from "react-day-picker"

import { cn } from "../utils"
import { classNameForOptions } from "./button"
import { type Locale, format } from "date-fns"

export type CalendarProps = React.ComponentProps<typeof DayPicker> & { captionFormat?: string, required?: boolean }

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
	captionFormat = 'LLLL yyyy',
	locale,
  ...props
}: CalendarProps) {
	
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
			formatters={{
				formatCaption: (date: Date) => format(date, captionFormat, { locale })
			}}
			locale={locale}
      className={cn("p-3", className)}
      classNames={{
        months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
        month: "space-y-4",
        caption: "flex justify-center pt-1 relative items-center",
        caption_label: "text-sm font-medium capitalize",
        nav: "space-x-1 flex items-center",
        nav_button: cn(
          classNameForOptions({ variant: "outline" }),
          "h-7 w-7 border-neutral-700 p-0 opacity-50 hover:opacity-100"
        ),
        nav_button_previous: "absolute left-1",
        nav_button_next: "absolute right-1",
        table: "w-full border-collapse space-y-1",
        head_row: "flex",
        head_cell: "text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]",
        row: "flex w-full mt-2",
        cell: "h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
        day: cn(
          classNameForOptions({}),
          "h-9 w-9 p-0 font-normal shadow-none aria-selected:opacity-100"
        ),
        day_range_end: "day-range-end",
        day_selected: "bg-primary-700 text-white hover:bg-primary-700 hover:text-primary-foreground focus:bg-primary-700 focus:text-primary-foreground",
        day_today: "bg-neutral-50 font-bold",
        day_outside: "day-outside  cursor-disabled select-none text-muted-foreground aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-100 text-neutral-300",
        day_disabled: "hover:bg-transparent text-neutral-300",
        day_range_middle: "aria-selected:bg-accent aria-selected:text-accent-foreground",
        day_hidden: "invisible",
        ...classNames,
      }}
      components={{
        IconLeft: ({ ...props }) => <AngleLeftIcon className="h-3 w-3 text-neutral-700" />,
        IconRight: ({ ...props }) => <AngleRightIcon className="h-3 w-3 text-neutral-700" />,
      }}
			
      {...props}
    />
  )
}
Calendar.displayName = "Calendar";

export { Calendar }
