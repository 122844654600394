import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgPermitsMatrixIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M7.464 1.172C7.464.522 6.94 0 6.286 0S5.107.522 5.107 1.172v1.953H3.143C1.409 3.125 0 4.526 0 6.25v15.625C0 23.599 1.41 25 3.143 25h15.714C20.591 25 22 23.599 22 21.875V6.25c0-1.724-1.41-3.125-3.143-3.125h-1.964V1.172c0-.65-.526-1.172-1.179-1.172s-1.178.522-1.178 1.172v1.953H7.464zM2.357 9.375h17.286v12.5c0 .43-.354.781-.786.781H3.143a.786.786 0 0 1-.786-.781zm2.357 4.297c0 .65.526 1.172 1.179 1.172h10.214a1.172 1.172 0 1 0 0-2.344H5.893c-.653 0-1.179.522-1.179 1.172m1.179 3.515a1.172 1.172 0 1 0 0 2.344h5.5a1.172 1.172 0 1 0 0-2.343z'
			/>
		</svg>
	);
});
export default memo(SvgPermitsMatrixIcon);
