import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgArrowUpIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 21 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M11.735.523a1.727 1.727 0 0 0-2.476 0L.512 9.453a1.815 1.815 0 0 0 0 2.527 1.727 1.727 0 0 0 2.477 0L8.75 6.093v17.121c0 .988.781 1.786 1.749 1.786s1.75-.798 1.75-1.786V6.093l5.761 5.882a1.727 1.727 0 0 0 2.477 0 1.815 1.815 0 0 0 0-2.528L11.74.517z'
			/>
		</svg>
	);
});
export default memo(SvgArrowUpIcon);
