import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgBellIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 25' ref={ref} {...props}>
			<path
				fill='#1E3050'
				fillRule='nonzero'
				d='M11 0c-.87 0-1.572.698-1.572 1.563V2.5c-3.585.723-6.286 3.877-6.286 7.656v1.24a9.74 9.74 0 0 1-2.151 6.1l-.732.912a1.16 1.16 0 0 0-.142 1.24 1.18 1.18 0 0 0 1.06.665h19.645a1.18 1.18 0 0 0 1.061-.665 1.16 1.16 0 0 0-.142-1.24l-.732-.908a9.76 9.76 0 0 1-2.151-6.104v-1.24c0-3.779-2.701-6.933-6.286-7.656v-.937C12.572.698 11.869 0 11 0m0 4.688c3.04 0 5.5 2.446 5.5 5.468v1.24c0 2.34.683 4.62 1.95 6.573H3.55a12.08 12.08 0 0 0 1.95-6.573v-1.24c0-3.022 2.46-5.469 5.5-5.469m3.143 17.187H7.857c0 .83.329 1.626.918 2.212.59.586 1.39.913 2.225.913s1.635-.327 2.225-.913.918-1.382.918-2.212'
			/>
		</svg>
	);
});
export default memo(SvgBellIcon);
