import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgFilterIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 22' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M0 2.048C0 .918.913 0 2.036 0h20.928C24.087 0 25 .918 25 2.048c0 .471-.161.928-.459 1.291l-8.135 10.043v7.032c0 .874-.708 1.586-1.577 1.586-.356 0-.703-.123-.981-.344L9.33 18.052a1.96 1.96 0 0 1-.737-1.537v-3.133L.459 3.339A2.04 2.04 0 0 1 0 2.048m2.686.31 7.988 9.86c.17.211.264.471.264.746v3.36l3.124 2.494v-5.854c0-.27.093-.535.264-.746l7.988-9.86z'
			/>
		</svg>
	);
});
export default memo(SvgFilterIcon);
