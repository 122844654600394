import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgLocationIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M24.857 2.65a1.92 1.92 0 0 0-.421-2.086 1.92 1.92 0 0 0-2.086-.42L1.195 8.796a1.925 1.925 0 0 0-1.16 2.151 1.92 1.92 0 0 0 1.888 1.55H12.5v10.578c0 .92.65 1.706 1.55 1.887a1.92 1.92 0 0 0 2.152-1.16z'
			/>
		</svg>
	);
});
export default memo(SvgLocationIcon);
