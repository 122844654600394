import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgSpotIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M10.673 24.438C13.21 21.295 19 13.678 19 9.4 19 4.21 14.745 0 9.5 0S0 4.21 0 9.4c0 4.278 5.79 11.895 8.327 15.038a1.505 1.505 0 0 0 2.346 0M9.5 6.266c1.749 0 3.167 1.403 3.167 3.133s-1.418 3.133-3.167 3.133S6.333 11.13 6.333 9.4c0-1.73 1.418-3.133 3.167-3.133'
			/>
		</svg>
	);
});
export default memo(SvgSpotIcon);
