import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgSidebarCatchIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 17' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M14.583 0c2.751 0 5.047 1.202 6.749 2.594 1.697 1.391 2.903 3.053 3.515 4.208.204.388.204.848 0 1.236-.612 1.155-1.818 2.817-3.515 4.209-1.702 1.396-3.998 2.598-6.75 2.598q-.336 0-.664-.024-2.791 1.352-3.583 1.154-.788-.197.016-2.036l.028-.064a12.4 12.4 0 0 1-2.545-1.624 15 15 0 0 1-1.55-1.476l-4.317 1.923a1.41 1.41 0 0 1-1.602-.316A1.33 1.33 0 0 1 .183 10.8L2.17 7.422.183 4.044a1.32 1.32 0 0 1 .182-1.581 1.41 1.41 0 0 1 1.602-.316L6.285 4.07c.456-.502.976-1.004 1.55-1.476C9.534 1.202 11.83 0 14.582 0m0 2.024c-2.105 0-3.94.915-5.403 2.117-.49.4-.933.831-1.333 1.27a2.13 2.13 0 0 1-2.43.497L3.065 4.862 3.98 6.42a1.98 1.98 0 0 1 0 2.007l-.915 1.556 2.356-1.05a2.13 2.13 0 0 1 2.43.498c.396.442.838.873 1.329 1.273 1.462 1.202 3.298 2.117 5.403 2.117s3.94-.92 5.403-2.117c1.315-1.075 2.287-2.349 2.834-3.28-.547-.933-1.519-2.202-2.834-3.282-1.462-1.202-3.298-2.117-5.403-2.117m3.472 4.049c.767 0 1.389.604 1.389 1.35 0 .745-.622 1.349-1.39 1.349-.766 0-1.388-.604-1.388-1.35 0-.745.622-1.35 1.389-1.35'
			/>
		</svg>
	);
});
export default memo(SvgSidebarCatchIcon);
