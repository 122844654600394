import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgBarometerIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='evenodd'
				d='M14.924 13.031V2.07c0-1.143-.97-2.07-2.166-2.07-1.197 0-2.167.927-2.167 2.07v10.783C7.933 13.633 6 15.995 6 18.79 6 22.22 8.91 25 12.5 25s6.5-2.78 6.5-6.21c0-2.61-1.688-4.84-4.076-5.759M12.5 22.604c-2.204 0-3.992-1.707-3.992-3.814 0-.054.006-.107.008-.16h7.97c.001.053.007.106.007.16 0 2.107-1.787 3.814-3.993 3.814'
			/>
		</svg>
	);
});
export default memo(SvgBarometerIcon);
