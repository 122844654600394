import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgUserPlusFilledIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 20' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M3.75 5a5 5 0 1 1 10 0 5 5 0 0 1-10 0M0 18.84a6.963 6.963 0 0 1 6.965-6.965h3.57A6.963 6.963 0 0 1 17.5 18.84c0 .64-.52 1.16-1.16 1.16H1.16C.52 20 0 19.48 0 18.84m19.688-6.652v-2.5h-2.5a.935.935 0 0 1-.938-.938c0-.52.418-.937.938-.937h2.5v-2.5c0-.52.417-.938.937-.938s.938.418.938.938v2.5h2.5c.519 0 .937.417.937.937s-.418.938-.937.938h-2.5v2.5c0 .519-.418.937-.938.937a.935.935 0 0 1-.937-.937'
			/>
		</svg>
	);
});
export default memo(SvgUserPlusFilledIcon);
