import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgSidebarAnalyticsIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25' ref={ref} {...props}>
			<g fill='none' fillRule='evenodd' stroke='currentColor'>
				<rect width={1} height={3.827} x={5.889} y={15.282} strokeWidth={1.389} rx={0.5} />
				<path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2.083} d='M10.408 12.508v6.25M14.575 9.383v9.375M18.741 6.258v12.5' />
				<rect width={22.917} height={22.917} x={1.042} y={1.042} strokeWidth={2.083} rx={2.778} />
			</g>
		</svg>
	);
});
export default memo(SvgSidebarAnalyticsIcon);
