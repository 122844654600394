import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgSearchIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M20.313 10.155c0 2.24-.727 4.31-1.953 5.99l6.182 6.186a1.564 1.564 0 0 1-2.212 2.211l-6.182-6.185a10.1 10.1 0 0 1-5.991 1.953C4.547 20.31 0 15.764 0 10.155 0 4.545 4.546 0 10.157 0s10.156 4.545 10.156 10.155m-10.156 7.03a7.032 7.032 0 0 0 6.09-10.545 7.032 7.032 0 0 0-13.122 3.515 7.03 7.03 0 0 0 7.032 7.03'
			/>
		</svg>
	);
});
export default memo(SvgSearchIcon);
