import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgCatchPlusIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 21' ref={ref} {...props}>
			<g fill='currentColor' fillRule='nonzero'>
				<path d='M13.101 4.773c2.416 0 4.432 1.124 5.926 2.426 1.49 1.301 2.55 2.855 3.087 3.936.179.363.179.793 0 1.156-.538 1.08-1.597 2.635-3.087 3.937-1.494 1.305-3.51 2.43-5.926 2.43l-.168-.005-.125.069q-3.105 1.694-3.902 1.482-.813-.218.305-2.556a11 11 0 0 1-2.035-1.416c-.618-.54-1.212-1.578-1.631-1.697q-.42-.117-3.415 2.056a1.19 1.19 0 0 1-1.475-.194 1.29 1.29 0 0 1-.198-1.522q1.725-2.707 1.725-3.16T.457 8.555A1.29 1.29 0 0 1 .66 7.034a1.19 1.19 0 0 1 1.475-.193q3.096 2.29 3.41 2.114c.315-.178 1.018-1.211 1.635-1.751 1.49-1.306 3.506-2.43 5.922-2.43m3.049 5.68c-.674 0-1.22.565-1.22 1.262s.546 1.262 1.22 1.262 1.22-.565 1.22-1.262-.547-1.262-1.22-1.262M24.88 1.603a.694.694 0 0 0-.695-.694.694.694 0 0 0-.695.694v3.12h-3.13a.694.694 0 1 0 0 1.387h3.13v3.12c0 .384.31.694.695.694s.695-.31.695-.693V6.11h3.13a.694.694 0 1 0 0-1.388h-3.13z' />
			</g>
		</svg>
	);
});
export default memo(SvgCatchPlusIcon);
