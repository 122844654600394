import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgAttachmentIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M18.578 2.388a3.39 3.39 0 0 0-4.767 0l-9.72 9.632a5.56 5.56 0 0 0 0 7.926 5.707 5.707 0 0 0 8.008 0l7.694-7.625a1.22 1.22 0 0 1 1.718 0 1.195 1.195 0 0 1 0 1.7l-7.695 7.631c-3.16 3.13-8.282 3.13-11.443 0a7.964 7.964 0 0 1-.005-11.338L12.094.682c2.264-2.243 5.937-2.243 8.206 0a5.7 5.7 0 0 1 0 8.122l-9.31 9.221a3.82 3.82 0 0 1-5.648-.311 3.736 3.736 0 0 1 .278-5.007l7.69-7.61a1.22 1.22 0 0 1 1.717 0 1.195 1.195 0 0 1 0 1.7l-7.685 7.616a1.336 1.336 0 0 0-.1 1.796 1.37 1.37 0 0 0 2.025.11l9.31-9.216a3.314 3.314 0 0 0 0-4.72z'
			/>
		</svg>
	);
});
export default memo(SvgAttachmentIcon);
