import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgGlobeIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M12.5 22.656c.361 0 1.318-.351 2.324-2.363.43-.864.801-1.914 1.074-3.105H9.102c.273 1.19.644 2.24 1.074 3.105 1.006 2.012 1.963 2.363 2.324 2.363m-3.784-7.812h7.568a22.6 22.6 0 0 0 0-4.688H8.716a22.6 22.6 0 0 0 0 4.688m.386-7.031h6.796c-.273-1.192-.644-2.242-1.074-3.106-1.006-2.012-1.963-2.363-2.324-2.363s-1.318.351-2.324 2.363c-.43.864-.801 1.914-1.074 3.106m9.536 2.343c.073.757.107 1.543.107 2.344s-.039 1.587-.107 2.344h3.745c.176-.752.273-1.538.273-2.344s-.093-1.592-.273-2.344h-3.745m2.87-2.344a10.2 10.2 0 0 0-4.804-4.56c.689 1.25 1.235 2.808 1.592 4.56h3.218zm-14.809 0c.357-1.752.904-3.305 1.592-4.56a10.2 10.2 0 0 0-4.805 4.56h3.218zm-4.082 2.344a10.3 10.3 0 0 0-.273 2.344c0 .806.093 1.592.273 2.344h3.745a24 24 0 0 1-.107-2.344c0-.8.039-1.587.107-2.344zm14.087 11.592a10.2 10.2 0 0 0 4.805-4.56h-3.213c-.357 1.752-.903 3.305-1.592 4.56m-8.408 0c-.689-1.25-1.235-2.808-1.592-4.56H3.486a10.2 10.2 0 0 0 4.805 4.56zM12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5 19.404 25 12.5 25'
			/>
		</svg>
	);
});
export default memo(SvgGlobeIcon);
