import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgWindDirectionIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 21 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M9.264.523a1.727 1.727 0 0 1 2.476 0l8.747 8.93c.503.513.65 1.277.378 1.947a1.75 1.75 0 0 1-1.619 1.105h-5.248v10.263C13.998 24 13.02 25 11.811 25H9.187c-1.208 0-2.186-.999-2.186-2.232V12.499H1.752a1.76 1.76 0 0 1-1.618-1.105 1.82 1.82 0 0 1 .377-1.947L9.258.517z'
			/>
		</svg>
	);
});
export default memo(SvgWindDirectionIcon);
