import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgCheckIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M24.477 4.094c.697.698.697 1.83 0 2.528L10.193 20.906a1.79 1.79 0 0 1-2.528 0L.523 13.764a1.788 1.788 0 0 1 2.528-2.528l5.88 5.876L21.956 4.094a1.79 1.79 0 0 1 2.527 0z'
			/>
		</svg>
	);
});
export default memo(SvgCheckIcon);
