import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgAngleRightIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M14.372 11.03a2.045 2.045 0 0 1 0 2.947L3.66 24.39a2.19 2.19 0 0 1-3.032 0 2.045 2.045 0 0 1 0-2.948L9.826 12.5.634 3.558a2.045 2.045 0 0 1 0-2.948 2.19 2.19 0 0 1 3.033 0L14.38 11.023z'
			/>
		</svg>
	);
});
export default memo(SvgAngleRightIcon);
