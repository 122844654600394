import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgMomentIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' viewBox='0 0 27 25' ref={ref} {...props}>
			<defs>
				<path id='a' d='m0 16.264 18.946-10.66V0H0z' />
			</defs>
			<g fill='none' fillRule='evenodd'>
				<g transform='translate(2.097)'>
					<mask id='b' fill='#fff'>
						<use xlinkHref='#a' />
					</mask>
					<g mask='url(#b)'>
						<g transform='translate(.05 .792)'>
							<rect width={16.841} height={15.918} x={0.526} y={0.526} stroke='#2C3036' strokeWidth={1.053} rx={1.544} />
							<ellipse cx={4.435} cy={3.763} fill='#2C3036' rx={1.134} ry={1.145} />
							<path
								fill='#2C3036'
								d='M16.44 3.097v6.362l-1.232-.196-.686 5.403H1.484l.075-6.813h3.995q-.812.688-.862 1.174c-.08.783 1.785.473 2.626.788q.789.296-.465 4.464l3.45-.386q-.959-4.081-2.147-4.434c-1.19-.352-1.772-.202-2.242-.432q-.405-.199.115-1.173h.325l3.177-3.162 1.739 1.525z'
							/>
						</g>
					</g>
				</g>
				<path
					fill='#2C3036'
					d='m.984 17.91.912-.525.353.612-.911.526Zm3.713 6.43.911-.525.354.612-.912.526Zm-1.89-7.482.912-.526.354.612-.912.527Zm3.713 6.43.911-.526.354.612-.912.527Zm-1.89-7.482.912-.527.354.613-.912.526Zm3.713 6.43.911-.527.354.613-.912.526Zm-1.89-7.483.912-.526.354.612-.912.527Zm3.713 6.43.911-.526.354.612-.911.527Zm-1.89-7.482.912-.527.354.613-.912.526Zm3.713 6.43.912-.527.353.613-.911.526Zm-1.89-7.483.912-.526.354.612-.912.526Zm3.713 6.43.912-.526.353.612-.911.527Zm-1.89-7.482.912-.527.354.613-.912.526Zm3.713 6.43.912-.527.353.613-.911.526Zm-1.89-7.483.912-.526.354.612-.912.526Zm3.713 6.43.912-.526.353.612-.911.526Zm-1.89-7.483.912-.526.354.613-.912.526Zm3.713 6.43.912-.526.353.613-.911.526Zm-1.889-7.482.911-.526.354.612-.911.526Zm3.712 6.43.912-.526.353.612-.911.526Zm-1.889-7.483.912-.526.353.612-.911.527Zm3.712 6.43.912-.526.354.612-.912.527Zm-1.889-7.482.912-.526.353.612-.911.526Zm3.712 6.43.912-.526.354.612-.912.526ZM1.515 18.829l6.38-3.684 3.006 5.206-6.381 3.684Zm14.584-8.42 6.381-3.684 3.005 5.205-6.38 3.684Zm-7.292 4.21 6.38-3.684 3.006 5.206-6.38 3.683Z'
				/>
			</g>
		</svg>
	);
});
export default memo(SvgMomentIcon);
