import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgAngleLeftIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M.628 13.97a2.045 2.045 0 0 1 0-2.947L11.34.61a2.19 2.19 0 0 1 3.032 0 2.045 2.045 0 0 1 0 2.948L5.174 12.5l9.192 8.942a2.045 2.045 0 0 1 0 2.948 2.19 2.19 0 0 1-3.033 0L.62 13.977z'
			/>
		</svg>
	);
});
export default memo(SvgAngleLeftIcon);
