import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgWeightIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M10.55 4.297a1.953 1.953 0 1 1 3.906 0 1.953 1.953 0 0 1-3.907 0m5.78 1.953a4.297 4.297 0 1 0-7.656 0H5.862a2.35 2.35 0 0 0-2.276 1.777L.071 22.09A2.339 2.339 0 0 0 2.346 25H22.66a2.342 2.342 0 0 0 2.27-2.915L21.414 8.022a2.34 2.34 0 0 0-2.27-1.772zm-3.828 2.344h6.641l3.516 14.062H2.346L5.862 8.594z'
			/>
		</svg>
	);
});
export default memo(SvgWeightIcon);
