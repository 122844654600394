import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgLifeRingIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M18.804 20.464a10.1 10.1 0 0 1-6.304 2.192c-2.383 0-4.575-.82-6.304-2.192l3.926-3.926a4.7 4.7 0 0 0 4.766 0l3.925 3.926zm3.325.01A12.47 12.47 0 0 0 25 12.5c0-3.032-1.08-5.806-2.871-7.974l.576-.57A1.171 1.171 0 0 0 21.05 2.3l-.576.57A12.47 12.47 0 0 0 12.5 0C9.468 0 6.694 1.08 4.526 2.871l-.57-.576A1.171 1.171 0 0 0 2.3 3.95l.57.576A12.47 12.47 0 0 0 0 12.5c0 3.032 1.08 5.806 2.871 7.974l-.576.57A1.171 1.171 0 0 0 3.95 22.7l.576-.575A12.44 12.44 0 0 0 12.5 25c3.032 0 5.806-1.08 7.974-2.871l.57.576A1.171 1.171 0 0 0 22.7 21.05l-.575-.576zm-1.665-1.665-3.926-3.926a4.7 4.7 0 0 0 0-4.766l3.926-3.926a10.13 10.13 0 0 1 2.192 6.309c0 2.383-.82 4.575-2.192 6.304zm-1.66-14.273-3.926 3.926a4.7 4.7 0 0 0-4.766 0L6.196 4.536A10.1 10.1 0 0 1 12.5 2.344c2.383 0 4.575.82 6.304 2.192M8.462 14.883l-3.926 3.92A10.1 10.1 0 0 1 2.344 12.5c0-2.383.82-4.575 2.192-6.304l3.926 3.926a4.7 4.7 0 0 0 0 4.766zm1.694-2.383a2.344 2.344 0 1 1 4.688 0 2.344 2.344 0 0 1-4.688 0'
			/>
		</svg>
	);
});
export default memo(SvgLifeRingIcon);
