import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgCameraPlusIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 22' ref={ref} {...props}>
			<g fill='currentColor' fillRule='nonzero'>
				<path d='M15.244 0c1.174 0 2.216.754 2.586 1.874l.08.243a5.2 5.2 0 0 0-1.701 2.302l-.595-1.8a.39.39 0 0 0-.37-.27H9.7a.39.39 0 0 0-.37.27l-.683 2.06c-.16.48-.609.802-1.11.802h-4.42a.784.784 0 0 0-.779.783v12.529c0 .43.351.783.78.783h18.707c.429 0 .78-.353.78-.783V11.18a5.25 5.25 0 0 0 2.34-1.424l-.002 9.036a3.13 3.13 0 0 1-3.118 3.132H3.118A3.13 3.13 0 0 1 0 18.793V6.264a3.13 3.13 0 0 1 3.118-3.132h3.576l.419-1.258.064-.173A2.72 2.72 0 0 1 9.7 0Zm-2.772 7.047c1.949 0 3.75 1.045 4.725 2.74a5.5 5.5 0 0 1 0 5.482 5.45 5.45 0 0 1-4.725 2.74c-3.014 0-5.457-2.453-5.457-5.48s2.443-5.482 5.457-5.482m0 2.35a3.125 3.125 0 0 0-3.118 3.131 3.125 3.125 0 0 0 3.118 3.133 3.125 3.125 0 0 0 3.117-3.133 3.125 3.125 0 0 0-3.117-3.132' />
				<path d='M21.704 3.224a.547.547 0 1 0-1.096 0v2.464h-2.464a.547.547 0 1 0 0 1.095h2.464v2.464a.547.547 0 1 0 1.096 0V6.783h2.464a.547.547 0 1 0 0-1.095h-2.464z' />
			</g>
		</svg>
	);
});
export default memo(SvgCameraPlusIcon);
