import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgBaitIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 25' ref={ref} {...props}>
			<g fill='none' fillRule='evenodd'>
				<path
					stroke='currentColor'
					strokeLinecap='round'
					strokeLinejoin='round'
					strokeWidth={1.852}
					d='M2.658 13.464Q1.06 15.125 1.06 18.033c0 2.907 1.997 5.4 5.591 5.815s7.681-2.245 7.036-7.976-5.744-5.077-5.354-9.266'
				/>
				<path fill='currentColor' d='M4.723 12.933c.128-1.318-.197-2.764-.694-2.517q-.498.247-2.949 3.617.38.465.112.61c2.51.252 1.68 1.932 2.284 1.482s1.12-1.875 1.247-3.192' />
				<path
					stroke='currentColor'
					strokeLinejoin='round'
					strokeWidth={1.852}
					d='M8.382 1.05c.706 0 1.346.295 1.809.777.45.468.73 1.114.73 1.83 0 .714-.28 1.36-.73 1.828a2.5 2.5 0 0 1-1.809.777 2.5 2.5 0 0 1-1.808-.777 2.64 2.64 0 0 1-.73-1.829c0-.715.28-1.361.73-1.83a2.5 2.5 0 0 1 1.808-.776Z'
				/>
			</g>
		</svg>
	);
});
export default memo(SvgBaitIcon);
