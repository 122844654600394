import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgSignatureIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 18' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M6.875 3.857c0-1.065.84-1.928 1.875-1.928s1.875.863 1.875 1.928v.318c0 1.104-.09 2.21-.273 3.298l-4.18 1.33c-1.442.458-2.422 1.828-2.422 3.38v3.005C3.75 16.738 4.973 18 6.484 18c.961 0 1.852-.518 2.344-1.366l.621-1.06a22 22 0 0 0 2.485-6.41l.039-.188L16.3 7.598l-.934 2.684a.99.99 0 0 0 .117.88c.176.257.461.41.766.41h5.313c.519 0 .937-.43.937-.965a.95.95 0 0 0-.937-.964h-3.981l1.113-3.21a.98.98 0 0 0-.207-.993.91.91 0 0 0-.953-.253L12.34 6.842q.159-1.327.16-2.667v-.318C12.5 1.727 10.82 0 8.75 0S5 1.728 5 3.857v1.607c0 .535.418.965.938.965.519 0 .937-.43.937-.965zm-.148 6.79 3.16-1.004a20 20 0 0 1-2.047 4.942l-.621 1.06a.86.86 0 0 1-.735.43.87.87 0 0 1-.859-.883v-3.01c0-.703.445-1.326 1.102-1.535M2.5 13.5H.938c-.52 0-.938.43-.938.964 0 .535.418.965.938.965h1.57a5 5 0 0 1-.008-.242zm8.469 1.929h13.094c.519 0 .937-.43.937-.965a.95.95 0 0 0-.937-.964H11.879a23 23 0 0 1-.91 1.929'
			/>
		</svg>
	);
});
export default memo(SvgSignatureIcon);
