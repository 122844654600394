import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgLockIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M7.071 6.25v3.125h7.858V6.25c0-2.158-1.758-3.906-3.929-3.906S7.071 4.092 7.071 6.25M4.714 9.375V6.25C4.714 2.798 7.528 0 11 0s6.286 2.798 6.286 6.25v3.125h1.571C20.591 9.375 22 10.776 22 12.5v9.375C22 23.599 20.59 25 18.857 25H3.143C1.409 25 0 23.599 0 21.875V12.5c0-1.724 1.41-3.125 3.143-3.125zM2.357 12.5v9.375c0 .43.354.781.786.781h15.714a.786.786 0 0 0 .786-.781V12.5a.786.786 0 0 0-.786-.781H3.143a.786.786 0 0 0-.786.781'
			/>
		</svg>
	);
});
export default memo(SvgLockIcon);
