import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgShieldCheckIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M2.85 6.235 12 2.425l9.15 3.81c.295.123.455.383.45.629-.02 4.487-1.92 12.24-9.315 15.716a.67.67 0 0 1-.565 0C4.32 19.099 2.425 11.35 2.4 6.859c0-.246.155-.501.45-.629zm19.235-2.17L12.67.142a1.64 1.64 0 0 0-1.34 0L1.915 4.065C.815 4.522-.005 5.587 0 6.874c.025 4.87 2.065 13.78 10.68 17.831a3.1 3.1 0 0 0 2.64 0C21.94 20.655 23.975 11.744 24 6.874c.005-1.287-.815-2.352-1.915-2.809m-4.435 6.196c.47-.461.47-1.208 0-1.664a1.215 1.215 0 0 0-1.695 0l-5.55 5.45-2.35-2.308a1.21 1.21 0 0 0-1.695 0 1.163 1.163 0 0 0 0 1.664l3.2 3.143a1.21 1.21 0 0 0 1.695 0z'
			/>
		</svg>
	);
});
export default memo(SvgShieldCheckIcon);
