import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgSidebarUsersIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 20' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M.059 17.918a6.96 6.96 0 0 1 6.906-6.043h3.57a6.963 6.963 0 0 1 6.96 6.965c.001.64-.518 1.16-1.16 1.16H1.16C.52 20 0 19.48 0 18.84q0-.47.059-.922M3.75 5a5 5 0 1 1 10 0 5 5 0 0 1-10 0m8.125 0a3.125 3.125 0 1 0-6.25 0 3.125 3.125 0 0 0 6.25 0m-4.91 8.75a5.09 5.09 0 0 0-5.04 4.375h13.653a4.964 4.964 0 0 0-.66-1.875 5.09 5.09 0 0 0-4.383-2.5zM23.8 20h-5.348c.192-.344.297-.738.297-1.16a8.2 8.2 0 0 0-2.965-6.32q.252-.022.516-.02h2.398A6.3 6.3 0 0 1 25 18.8a1.2 1.2 0 0 1-1.2 1.2m-6.926-10a4.37 4.37 0 0 1-3.098-1.285A6.22 6.22 0 0 0 15 5a6.2 6.2 0 0 0-.715-2.902 4.37 4.37 0 0 1 2.59-.848 4.373 4.373 0 0 1 4.375 4.375A4.373 4.373 0 0 1 16.875 10'
			/>
		</svg>
	);
});
export default memo(SvgSidebarUsersIcon);
