import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgFileExportIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 22' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M14.582 19.25a.693.693 0 0 1-.694.688H2.778a.693.693 0 0 1-.695-.688V2.75c0-.378.313-.687.695-.687H9.72V5.5c0 .76.621 1.375 1.39 1.375h3.471V11h2.083V6.639c0-.73-.29-1.431-.811-1.947L11.922.804A2.78 2.78 0 0 0 9.96 0H2.778C1.246 0 0 1.233 0 2.75v16.5C0 20.767 1.246 22 2.778 22h11.11c1.532 0 2.777-1.233 2.777-2.75v-3.437h-2.083zm6.64-10.012a1.045 1.045 0 0 0-1.471 0 1.024 1.024 0 0 0 0 1.457l1.692 1.676H9.374c-.577 0-1.041.46-1.041 1.031s.464 1.031 1.041 1.031h12.07L19.75 16.11a1.02 1.02 0 0 0 0 1.457c.408.4 1.068.404 1.471 0l3.472-3.438a1.02 1.02 0 0 0 0-1.457l-3.472-3.437z'
			/>
		</svg>
	);
});
export default memo(SvgFileExportIcon);
