import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgVisaIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 8' ref={ref} {...props}>
			<path
				fill='#1434CB'
				fillRule='nonzero'
				d='M16.274.004c-1.774 0-3.36.92-3.36 2.62 0 1.948 2.812 2.083 2.812 3.062 0 .412-.472.781-1.279.781-1.145 0-2-.516-2-.516l-.366 1.715s.985.436 2.294.436c1.94 0 3.466-.965 3.466-2.694 0-2.059-2.824-2.19-2.824-3.098 0-.323.388-.677 1.192-.677a4.05 4.05 0 0 1 1.648.375l.359-1.656S17.41.004 16.274.004M.043.13 0 .38s.746.137 1.419.41c.865.312.927.494 1.073 1.059L4.08 7.973h2.13L9.49.129H7.365L5.257 5.462l-.86-4.52C4.318.424 3.92.129 3.43.129zm10.3 0L8.678 7.973h2.026l1.66-7.844zm11.3 0c-.49 0-.748.262-.938.719l-2.968 7.125h2.125l.41-1.188h2.589l.25 1.188h1.874L23.35.129zm.276 2.12.63 2.943H20.86z'
			/>
		</svg>
	);
});
export default memo(SvgVisaIcon);
