import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgPenSquareIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='m22.162 3.243.595.595c.463.462.463 1.21 0 1.668l-1.431 1.437-2.269-2.269 1.432-1.431a1.176 1.176 0 0 1 1.668 0zM10.786 12.95l6.603-6.609 2.269 2.269-6.609 6.603c-.142.143-.32.246-.512.3l-2.878.822.822-2.878c.054-.192.157-.37.3-.512zM18.82 1.575l-9.708 9.703a3.5 3.5 0 0 0-.9 1.53l-1.408 4.92a1.18 1.18 0 0 0 1.461 1.462l4.921-1.407a3.56 3.56 0 0 0 1.53-.9l9.708-9.704a3.543 3.543 0 0 0 0-5.01l-.595-.594a3.543 3.543 0 0 0-5.009 0M4.793 3.494a4.33 4.33 0 0 0-4.33 4.33v13.383a4.33 4.33 0 0 0 4.33 4.33h13.383a4.33 4.33 0 0 0 4.33-4.33v-5.51c0-.655-.526-1.181-1.18-1.181-.655 0-1.181.526-1.181 1.18v5.511c0 1.088-.881 1.969-1.969 1.969H4.793a1.97 1.97 0 0 1-1.969-1.969V7.824c0-1.088.881-1.969 1.969-1.969h5.51c.655 0 1.181-.526 1.181-1.18 0-.655-.526-1.181-1.18-1.181z'
			/>
		</svg>
	);
});
export default memo(SvgPenSquareIcon);
