import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgAppleLogoIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 21 25' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M20.56 19.483a13.6 13.6 0 0 1-1.34 2.416q-1.058 1.511-1.727 2.093-1.033.952-2.22.98-.852.001-2.05-.491-1.198-.49-2.206-.49-1.056 0-2.27.49-1.213.492-1.96.517-1.138.048-2.27-1.006-.723-.633-1.805-2.167-1.162-1.638-1.91-3.804Q0 15.681 0 13.488q0-2.514 1.084-4.332a6.37 6.37 0 0 1 2.27-2.303 6.1 6.1 0 0 1 3.07-.868q.903.001 2.375.554 1.47.554 1.883.555.311 0 2.089-.654 1.679-.605 2.838-.505 3.148.253 4.722 2.492-2.814 1.711-2.786 4.784.026 2.395 1.728 3.977.77.734 1.727 1.136-.207.603-.44 1.159M15.75.5q-.001 1.877-1.364 3.498c-1.096 1.285-2.421 2.027-3.858 1.91a4 4 0 0 1-.03-.474c0-1.2.522-2.485 1.447-3.535q.694-.798 1.762-1.326Q14.774.05 15.723 0q.027.25.026.5'
			/>
		</svg>
	);
});
export default memo(SvgAppleLogoIcon);
