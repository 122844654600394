import React, { memo, forwardRef, type SVGProps, ForwardedRef } from 'react';
interface IconProps extends SVGProps<SVGSVGElement> {
	className?: string;
}
const SvgCircleArrowRightIcon = forwardRef((props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26' ref={ref} {...props}>
			<path
				fill='currentColor'
				fillRule='nonzero'
				d='M23.156 13c0 5.61-4.547 10.156-10.156 10.156S2.844 18.61 2.844 13 7.39 2.844 13 2.844 23.156 7.39 23.156 13M.5 13c0 6.904 5.596 12.5 12.5 12.5S25.5 19.904 25.5 13 19.904.5 13 .5.5 6.096.5 13m14.111 5.127 4.297-4.297a1.167 1.167 0 0 0 0-1.655l-4.297-4.297a1.171 1.171 0 0 0-1.655 1.655l2.295 2.295h-7.33A1.17 1.17 0 0 0 6.75 13c0 .65.522 1.172 1.172 1.172h7.329l-2.295 2.295a1.171 1.171 0 0 0 1.655 1.655z'
			/>
		</svg>
	);
});
export default memo(SvgCircleArrowRightIcon);
